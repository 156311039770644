import { gql } from '@apollo/client';
import { CustomerDocument } from '@ab-core/graphql/dist';

export type CustomerDocumentType = {
    customerDocument: CustomerDocument;
};

const DOCUMENT_QUERY = gql`
    query DocumentQuery($documentType: CustomerDocumentType!, $documentNumber: String!) {
        customerDocument(documentType: $documentType, documentNumber: $documentNumber) {
            fileName
            binData
        }
    }
`;

export default DOCUMENT_QUERY;
