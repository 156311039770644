import { gql } from '@apollo/client';

export const GET_PROMOTIONAL_TEMPLATE = gql`
    query getPromotionalSheets {
        getPromotionalSheets {
            headline
            priceColor
            legalNotice
            showPrice
            showUvp
            showVat
            footerText
            companyImage
            lastName
            firstName
            email
            phoneNumber
            showEan
            showProductDescription
            showTechnicalData
        }
    }
`;
