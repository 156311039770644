import { gql } from '@apollo/client';
import { MONEY_OBJECT } from '../fragments/moneyObject';

export const OFFERS_QUERY = gql`
    query offers {
        offers {
            id
            bindingPeriod
            project
            offerNumber
            offerDate
            offerText
            contactPerson
            lastModifiedAt
            purchaser
            priceObject {
                value {
                    ...MoneyObject
                }
            }
        }
    }
    ${MONEY_OBJECT}
`;
