import type { Product, ProductQueryResult, QueryProductsArgs } from '@ab-core/graphql/dist/';
import type { MappedSearchResultDocument } from '@ab-core/search/mappers/productSearchMapper';
import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { PRODUCT_PROMOTION_QUERY } from './productPromotionQuery';

type Data = {
    products?: ProductQueryResult;
};

type UseProductPromotionReturnType = {
    products?: Array<MappedSearchResultDocument>;
    productsLoading: boolean;
    productsError?: ApolloError;
};

export const useProductPromotion = (articleList: Array<string>): UseProductPromotionReturnType => {
    const { data, loading, error } = useQuery<Data, QueryProductsArgs>(PRODUCT_PROMOTION_QUERY, {
        variables: {
            skus: articleList,
            limit: 200
        },
        fetchPolicy: 'network-only',
        skip: !articleList
    });

    const mapProductToMappedSearchResultDocument = (product: Product): MappedSearchResultDocument => {
        return {
            packagingSize: product.packagingSize ?? undefined,
            id: product.id,
            sku: product.sku ?? '',
            productName: product.productName ?? '',
            relationType: product.relationType ?? undefined,
            ean: product.ean ?? '',
            supplierId: product.supplierId ?? '',
            image: product.image ?? '',
            procuredProduct: product.procuredProduct ?? false,
            productCocontractor: product.productCocontractor ? Boolean(product.productCocontractor) : false,
            salesUnit: product.salesUnit ?? 0,
            quantityUnit: product.quantityUnit ?? '',
            category: product.categoryKey ?? '',
            isTecselect: product.isTecselect ?? undefined,
            isAbakus: product.isAbakus ?? undefined,
            isAbakusPlus: product.isAbakusPlus ?? undefined,
            isUnqualifiedContractProduct: product.isUnqualifiedContractProduct ?? false,
            hasAccessories: Boolean(product.accessoriesProducts?.length),
            hasAlternatives: Boolean(product.alternativesEnventa?.length),
            onlineAvailable: product.onlineAvailable ?? undefined,
            isPromotion: product.isPromotion ?? undefined,
            topProduct: product.topProduct ?? undefined,
            reach: product.reachInfo ?? undefined,
            cableSnap: undefined,
            chargeNr: undefined,
            quantity: undefined,
            position: undefined,
            positionText: undefined,
            priceObject: undefined,
            customerArticleNumber: product.customerArticleNumber ?? undefined
        };
    };

    const mappedProducts = [];

    if (data?.products?.results) {
        for (const product of data.products.results) {
            mappedProducts.push(mapProductToMappedSearchResultDocument(product));
        }
    }

    return {
        productsLoading: loading,
        productsError: error,
        products: mappedProducts
    };
};
