import { gql } from '@apollo/client';
import { Cart } from '@ab-core/graphql/dist';

import { CART_OBJECT } from '../../../fragments/cartFragment';

export type UpdateCartResponseType = {
    upsertLineItemToCarts: Array<Cart>;
};
export const UPDATE_CART = gql`
    mutation upsertLineItem($cartIds: [String!]!, $lineItemInput: [LineItemInput!]!, $tracking: Tracking) {
        upsertLineItemToCarts(cartIds: $cartIds, lineItemInput: $lineItemInput, tracking: $tracking) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
