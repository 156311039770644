import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const ComparisonContainer = styled.div`
    position: fixed;
    z-index: ${Z_INDEX.MODAL};
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0, 100%);
    transition: all 250ms ease-in-out;
    background: ${Colors.white};
    border: 1px solid ${Colors.gray50};
    display: grid;
    grid-template-columns: 2fr auto 2fr auto 2fr 1fr auto;
    gap: ${Spacing.small3};
    align-items: center;
    padding: ${Spacing.small3} ${Spacing.medium};
    &.ab-compare-container-is-open {
        transform: translate(0);
    }
`;

export const Separator = styled.div`
    width: 1px;
    height: 100%;
    background: ${Colors.gray50};
`;

export const ButtonContainer = styled.div`
    grid-column-end: -2;
`;

export const IconContainer = styled.div`
    grid-column-end: -1;
    cursor: pointer;
    align-self: baseline;
`;

export const MinimizeContainer = styled.div`
    width: 25px;
    height: 25px;
    background: ${Colors.white};
    position: absolute;
    left: -1px;
    top: -25px;
    border: 1px solid ${Colors.gray50};
    border-bottom: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;
