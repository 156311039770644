import { BreakpointsMaxQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;

    &.ab-sidebar-sidebarPosition-right {
        flex-direction: row-reverse;
    }
    @media ${BreakpointsMaxQuery.lg} {
        &.ab-sidebar-showMobile {
            flex-direction: column-reverse;
        }
    }
    * {
        ::-webkit-scrollbar {
            width: 2px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px ${Colors.white};
            border-radius: ${Radius.minimal};
        }

        ::-webkit-scrollbar-thumb {
            background: ${Colors.primary};
            border-radius: ${Radius.minimal};
        }
    }
`;

export const SidebarWrapper = styled.div`
    position: fixed;
    width: 320px;
    height: 100vh;
    background-color: ${Colors.white};
    box-shadow: ${Shadows.navigationShadow};
    overflow-y: auto;
    padding-bottom: 200px;
    z-index: ${Z_INDEX.STICKY};
    @media ${BreakpointsMaxQuery.lg} {
        display: none;
        &.ab-sidebar-showMobile {
            position: relative;
            display: flex;
            height: auto;
            width: 100%;
            padding-bottom: 0;
        }
    }
`;

export const ChildrenWrapper = styled.div`
    width: 100vw;
    background-color: ${Colors.backgroundShade};
    padding: ${Spacing.small4};
    padding-left: 320px;

    &.ab-sidebar-sidebarPosition-right {
        padding-left: 0px;
        padding-right: 320px;
        @media ${BreakpointsMaxQuery.lg} {
            padding-right: 0px;
        }
    }
    &.ab-sidebar-sidebarPosition-left {
        padding-left: 320px;
        padding-right: 0px;
        @media ${BreakpointsMaxQuery.lg} {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
`;
