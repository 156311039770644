import { Icon } from '@ab-core/icon';
import { Link } from '@ab-core/link';
import { navigationStore } from '@ab-core/store';
import { FontTypes } from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { ChevronIcon, Child, MenuItem, MenuItemMobile, Parent, StyledLink, StyledMenu, Title } from './styled';
import type { NavigationElementParentProps, NavigationElementProps } from './types';

const Element: FC<NavigationElementProps> = ({
    name = '',
    slug,
    navigationElements,
    externalLink,
    handleClick,
    id,
    active,
    ancestors,
    onLeave
}) => {
    const timeout = 350;

    if (typeof window === 'undefined') {
        return;
    }

    let timer: ReturnType<typeof setTimeout>;

    const gotToPage = (url: string) => {
        navigate(url);
        navigationStore.setters.setShopNavigation(false);
    };
    const handleMouseEnter = () => {
        timer = setTimeout(() => {
            handleClick(ancestors, id);
        }, timeout);
    };
    const handleMouseLeave = () => clearTimeout(timer);
    const toggleSubMenu = () => {
        if (active) {
            // eslint-disable-next-line
            handleClick(ancestors?.slice(0, ancestors.length - 1), ancestors?.at(-1));
        } else {
            handleClick(ancestors, id);
        }
    };
    const getAnchestorsLength = () => ancestors?.length || 0;

    return (
        <Child level={getAnchestorsLength()}>
            {externalLink || slug ? (
                <StyledLink
                    level={getAnchestorsLength()}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div onClick={onLeave}>
                        <div className="pointer" onClick={() => gotToPage(externalLink || `/${slug}`)}>
                            <Title type={FontTypes.Subtitle} color="gray70" content={name} />
                        </div>
                    </div>

                    {navigationElements && navigationElements.length && navigationElements.length > 0 && (
                        <ChevronIcon active={active} onClick={toggleSubMenu}>
                            <Icon size="small3" color={active ? 'black' : 'gray70'} name="ChevronUp" />
                        </ChevronIcon>
                    )}
                </StyledLink>
            ) : (
                <>
                    <MenuItem
                        level={getAnchestorsLength()}
                        onClick={toggleSubMenu}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Title type={FontTypes.Subtitle} color="gray70" content={name} />
                        {navigationElements && navigationElements.length && navigationElements.length > 0 && (
                            <ChevronIcon active={active} onClick={toggleSubMenu}>
                                <Icon size="small3" color={active ? 'black' : 'gray70'} name="ChevronUp" />
                            </ChevronIcon>
                        )}
                    </MenuItem>

                    <MenuItemMobile level={getAnchestorsLength()} onClick={toggleSubMenu}>
                        <Title type={FontTypes.Subtitle} color={active ? 'black' : 'gray70'} content={name} />
                        {navigationElements && navigationElements.length && navigationElements.length > 0 && (
                            <ChevronIcon active={active} onClick={toggleSubMenu}>
                                <Icon size="small3" color={active ? 'black' : 'gray70'} name="ChevronUp" />
                            </ChevronIcon>
                        )}
                    </MenuItemMobile>
                </>
            )}
            {navigationElements && active && (
                <Menu
                    id={id}
                    name={name}
                    slug={slug}
                    navigationElements={navigationElements}
                    externalLink={externalLink}
                    handleClick={handleClick}
                    onLeave={onLeave}
                />
            )}
        </Child>
    );
};

export const Menu: FC<NavigationElementParentProps> = ({
    name = '',
    slug = '',
    navigationElements,
    externalLink = '',
    identifier,
    handleClick,
    onMouseEnter,
    onMouseLeave,
    onLeave,
    first = false
}) => (
    <StyledMenu
        first={first}
        identifier={identifier}
        windowWidth={window.visualViewport?.width}
        onMouseEnter={identifier === 'root' ? onMouseEnter : undefined}
        onMouseLeave={identifier === 'root' ? onMouseLeave : undefined}
    >
        {externalLink || slug ? (
            <Link to={externalLink || `/${slug}`}>
                <Parent content={name} />
            </Link>
        ) : (
            <Parent content={name} />
        )}
        {Array.isArray(navigationElements) &&
            navigationElements.map((navigationElement) => (
                <Element
                    {...navigationElement}
                    key={navigationElement.id}
                    handleClick={handleClick}
                    onLeave={onLeave}
                />
            ))}
    </StyledMenu>
);
