import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { Cart } from '@ab-core/graphql/dist/index';
import { useEffect, useState } from 'react';
import { RECEIVED_CARTS } from './getReceivedCartsQuery';
import cartMapper, { MappedCart } from '../mappers/cartMapper';

type Data = {
    getCarts?: Cart[];
};

type UseReceivedCartsType = {
    receivedCarts?: MappedCart[];
    receivedCartsError?: ApolloError;
    receivedCartsLoading: boolean;
    receivedCartsRefetch: () => Promise<ApolloQueryResult<Data>>;
};
const useReceivedCarts = (withActiveCart = false, skip = false): UseReceivedCartsType => {
    const { error, data, loading, refetch } = useQuery<Data>(RECEIVED_CARTS, {
        fetchPolicy: 'network-only',
        skip
    });

    const [receivedCarts, setReceivedCarts] = useState<Array<MappedCart>>([]);

    useEffect(() => {
        const mappedCarts = loading || error ? [] : data?.getCarts?.map((cart) => cartMapper(cart));
        const receivedCartsTemp = mappedCarts
            ?.filter((mappedCart) => (withActiveCart ? true : !mappedCart?.isActiveCart))
            ?.sort((a, b) => (b?.lastModifiedAt || new Date()).getTime() - (a?.lastModifiedAt || new Date()).getTime());
        setReceivedCarts(receivedCartsTemp || []);
    }, [data]);
    return {
        receivedCarts,
        receivedCartsError: error,
        receivedCartsLoading: loading,
        receivedCartsRefetch: refetch
    };
};

export default useReceivedCarts;
