import { Account } from '@ab-core/types/src';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { useState } from 'react';
import GET_ACCOUNTS from './getAccounts';

type Data = {
    accounts?: Array<Account>;
    loading: boolean;
    success?: boolean;
    refetch: () => Promise<ApolloQueryResult<Data>>;
    getAccounts?: Array<Account>;
};

export const useAccounts = (customerId?: string, skip = false): Data => {
    const [success, setSuccess] = useState(false);

    const { data, loading, refetch } = useQuery<Data>(GET_ACCOUNTS, {
        skip: skip || !customerId,
        onCompleted: () => {
            setSuccess(true);
        }
    });
    const accounts: Array<Account> = JSON.parse(JSON.stringify(data?.getAccounts || []));
    if (customerId) {
        accounts.sort((a, b) => {
            if (a.accountId === customerId) {
                return -1;
            }
            if (b.accountId === customerId) {
                return 1;
            }
            return 0;
        });
    }

    return {
        accounts,
        loading,
        refetch,
        success
    };
};
