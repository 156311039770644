import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import * as Icons from './icons';

export type IconProps = React.ComponentPropsWithoutRef<'svg'> & {
    name: keyof typeof Icons;
    color?: keyof typeof Colors;
    size?: keyof typeof Spacing;
    rotation?: number;
};

const Icon: FC<IconProps> = (props) => {
    const { name, color, size = 'small5', rotation = 0, ...rest } = props;

    const sizeProp = Spacing[size];
    const colorProp = color ? Colors[color] : 'currentColor';

    const IconElement = Icons[name];

    if (!IconElement) {
        return <></>;
    }

    return (
        <IconElement
            color={colorProp}
            rotation={rotation}
            size={sizeProp}
            {...getTestIdProp(`${name}-icon`)}
            {...rest}
        />
    );
};

export { Icon, Icons };
