import { ROUTES } from '@ab-core/functions';
import type { Customer, MutationUpdateAccountContactRelationArgs } from '@ab-core/graphql/dist/index';
import { UPDATE_ACCOUNT_CONTACT_RELATION, useAuth } from '@ab-core/hooks';
import { useSetActiveCart } from '@ab-core/hooks/cartNew/activeCart/checkForActiveCart';
import { useAccounts } from '@ab-core/hooks/user/accounts/useAccounts';
import { useForceCustomerData } from '@ab-core/hooks/user/customerData/useForceCustomerData';
import { Loader } from '@ab-core/loader';
import useText from '@ab-core/localization/src/useText';
import Modal from '@ab-core/modal';
import { modalOpenState } from '@ab-core/seo/src/state/modalOpenState';
import { SHEET_STYLE_TYPE, Sheet } from '@ab-core/sheet';
import { navigationStore } from '@ab-core/store';
import { getTestIdProp } from '@ab-core/testing';
import Text, { FontTypes } from '@ab-core/text';
import { useMutation, useReactiveVar } from '@apollo/client';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import {
    IconStyled,
    Label,
    ListElement,
    LockedTextStyled,
    SelectedCustomerLabel,
    UserLockedInfo,
    Wrapper
} from './styled';

const CustomerSelectModal: FC = () => {
    const USER_LOCKED_STATE_1 = 1;
    const USER_LOCKED_STATE_4 = 4;
    const USER_LOCKED_STATE_999 = 999;
    const selectedCustomerLabelText = useText('accountSelection.selectedCustomer');
    const { user } = useAuth();
    const [customerId, setCustomerId] = useState(user?.activeAccount?.accountId);
    const [loadingId, setLoadingId] = useState('');
    const [pending, setPending] = useState(false);
    let stateModalOpen = useReactiveVar(modalOpenState) || false;
    const { accounts } = useAccounts(customerId);
    const setModalOpen = (openModal: boolean) => {
        stateModalOpen = openModal;

        const newState = JSON.parse(JSON.stringify(stateModalOpen));
        modalOpenState(newState);
    };
    const [selectedAccount, setSelectedAccount] = useState<string>('');
    const [accountIsSelected, setAccountIsSelected] = useState<boolean>(false);

    type Data = {
        updateAccountContactRelation?: Customer[];
    };

    const { checkForActiveCart } = useSetActiveCart();
    const { refetch } = useForceCustomerData();
    const TIMEOUT = 100;
    const [updateAccountContactRelation, { client }] = useMutation<Data, MutationUpdateAccountContactRelationArgs>(
        UPDATE_ACCOUNT_CONTACT_RELATION,
        {
            onCompleted: async (mutationData) => {
                if (!Array.isArray(mutationData?.updateAccountContactRelation)) {
                    return;
                }

                await client.resetStore();
                await refetch({ force: true });
                setAccountIsSelected(false);
                navigate(ROUTES.SHOP);
                await checkForActiveCart();
                setCustomerId(selectedAccount);
                setLoadingId('');
                setPending(false);
                setModalOpen(false);
                setTimeout(() => {
                    location.reload();
                }, TIMEOUT);
            }
        }
    );

    useEffect(() => {
        if (selectedAccount && !accountIsSelected) {
            setAccountIsSelected(true);
            setPending(true);
            setLoadingId(selectedAccount);
            updateAccountContactRelation({
                variables: {
                    accountContactRelationInput: {
                        accountId: selectedAccount
                    }
                }
            });
        }
    }, [selectedAccount]);

    useEffect(() => {
        navigationStore.setters.setUserNavigation(false);

        setCustomerId(user?.activeAccount?.accountId);
    }, [stateModalOpen]);

    const userLockedText =
        user?.activeAccount?.lock === USER_LOCKED_STATE_999
            ? useText('userLocked.accountText999')
            : useText('userLocked.accountText');

    return (
        <Modal open={stateModalOpen} onClose={() => setModalOpen(false)} headerText={useText('accountSelection.title')}>
            <Wrapper {...getTestIdProp('accounts')}>
                {Array.isArray(accounts) &&
                    accounts.map((customer, key) => (
                        <React.Fragment key={key}>
                            <ListElement
                                onClick={() =>
                                    customer?.lock !== USER_LOCKED_STATE_1 &&
                                    customer?.lock !== USER_LOCKED_STATE_4 &&
                                    customer?.lock !== USER_LOCKED_STATE_999 &&
                                    setSelectedAccount(customer.accountId)
                                }
                            >
                                <Sheet
                                    styleType={SHEET_STYLE_TYPE.FLAT}
                                    active={customer?.accountId === customerId && !pending}
                                >
                                    {pending && customer?.accountId === loadingId && (
                                        <Loader {...getTestIdProp('account-modal-loader')} />
                                    )}
                                    <SelectedCustomerLabel selected={customer?.accountId === customerId}>
                                        <Text
                                            type={FontTypes.Subtitle}
                                            color="gray70"
                                            content={selectedCustomerLabelText}
                                        />
                                    </SelectedCustomerLabel>
                                    <Label>
                                        <Text color="black" content={`${customer.customerId} ${customer.company}`} />
                                    </Label>

                                    {(customer?.lock === USER_LOCKED_STATE_1 ||
                                        customer?.lock === USER_LOCKED_STATE_4 ||
                                        customer?.lock === USER_LOCKED_STATE_999) && (
                                        <UserLockedInfo>
                                            <IconStyled name="Warning" color="warning" />
                                            <LockedTextStyled type={FontTypes.Subtitle}>
                                                {userLockedText}
                                            </LockedTextStyled>
                                        </UserLockedInfo>
                                    )}
                                </Sheet>
                            </ListElement>
                        </React.Fragment>
                    ))}
            </Wrapper>
        </Modal>
    );
};

export default CustomerSelectModal;
