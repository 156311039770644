import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import styled, { keyframes } from 'styled-components';

const fadeInDesktop = keyframes`
  0% {
      opacity: 0;
      transform: translateX(20%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }

`;
const fadeInMobile = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }

`;

export const Notification = styled.div`
    width: 100%;
    height: auto;
    padding: ${Spacing.small4};
    background-color: ${Colors.white};
    border-radius: ${Radius.rounded};
    display: grid;
    gap: ${Spacing.small4};
    grid-template-columns: 20px auto 20px;
    box-shadow: ${Shadows.navigationShadow};
    border-left: 6px solid;
    animation-name: ${fadeInMobile};
    @media ${BreakpointsMinQuery.md} {
        animation-name: ${fadeInDesktop};
    }
    animation-duration: 0.75s;

    &.ab-toast-styleType-success {
        border-color: ${Colors.success};
    }
    &.ab-toast-styleType-error {
        border-color: ${Colors.error};
    }
    &.ab-toast-styleType-info {
        border-color: ${Colors.info};
    }
    &.ab-toast-styleType-warning {
        border-color: ${Colors.warning};
    }
`;
export const DescriptionWrapper = styled.div`
    margin-top: ${Spacing.small2};
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;

    gap: 2px;
`;

export const IconWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;
export const CloseWrapper = styled.div`
    align-self: flex-start;
    background-color: transparent;
    cursor: pointer;
    line-height: 0;
`;
