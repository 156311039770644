import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

type StyledProps = {
    active?: boolean;
    footer?: boolean;
    display?: string;
};

export const CloseHeader = styled.div`
    text-align: left;
    margin-left: ${Spacing.small5};
    margin-right: ${Spacing.small5};
    margin-top: ${Spacing.small5};
`;

export const CloseHeaderContent = styled.div`
    min-height: 40px;
`;

export const Spacer = styled.div`
    margin-left: ${Spacing.small5};
    margin-right: ${Spacing.small5};
    padding-bottom: ${Spacing.small5};
    border-top: 1px solid ${Colors.gray30};
    @media ${BreakpointsMinQuery.sm} {
        margin-left: ${Spacing.medium3};
        margin-right: ${Spacing.medium3};
    }
`;

export const Footer = styled.div<StyledProps>`
    min-height: ${({ footer }) => (footer ? '2vh' : '0%')};
    padding-top: 2px;

    margin-left: ${Spacing.small5};
    margin-right: ${Spacing.small5};
    @media ${BreakpointsMinQuery.sm} {
        margin-left: ${Spacing.medium3};
        margin-right: ${Spacing.medium3};
    }
`;

export const ChildrenContent = styled.div<StyledProps>`
    padding-left: ${Spacing.small5};
    padding-right: ${Spacing.small5};
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: ${({ footer }) => (footer ? '50vh;' : '80vh')};
    @media ${BreakpointsMinQuery.xs} {
        height: ${({ footer }) => (footer ? '56vh;' : '82vh')};
    }
    @media (min-width: 390px) {
        height: ${({ footer }) => (footer ? '65vh;' : '85vh')};
    }
    @media ${BreakpointsMinQuery.sm} {
        height: ${({ footer }) => (footer ? '70vh;' : '85vh')};
        padding-left: 40px;
        padding-right: 40px;
    }

    display: flex;
    width: 100%;

    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: ${Radius.minimal};
    }

    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: ${Radius.minimal};
    }
`;

export const Content = styled.div`
    margin-top: -10px;
`;

export const BottomSheet = styled.div<StyledProps>`
    background-color: white;
    z-index: ${Z_INDEX.MODAL};
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    position: fixed;
    transition: 0.35s ease-in-out;
    overflow: initial !important;
    transform: ${({ active }) => (active ? 'translateY(0px)' : 'translateY(100%)')};
`;

export const Container = styled.div<StyledProps>`
    position: fixed;
    z-index: ${Z_INDEX.MODAL};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    transition: 0.35s ease-in-out;
    transform: ${({ active }) => (!active ? 'translateY(100%)' : 'translateY(0px)')};
    display: ${({ active, display }) => active && display};
`;

export const Backdrop = styled.div<StyledProps>`
    width: 100vw;
    height: 100vh;
    background-color: ${Colors.black};
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.MODAL};
    transition: 0.35s ease-in-out;
    opacity: ${({ active }) => (active ? '50%' : '0%')};
`;
