import { SHOPPING_LIST_QUERY, useAuth } from '@ab-core/hooks';
import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { MappedLineItem } from './lineItemMapper';
import lineItemMapper from './lineItemMapper';
import { ShareState } from './useUpdateShareState';

export type UseShoppingListType = {
    lineItems: MappedLineItem[] | undefined;
    name?: string;
    shoppingListShareState?: string;
    shoppingListCreatedBy?: string;
    shoppingListId?: string;
    shoppingListError?: ApolloError;
    shoppingListLoading: boolean;
};

const useShoppingList = (id?: string, skip = true): UseShoppingListType => {
    const { user } = useAuth();
    const { error, data, loading } = useQuery(SHOPPING_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            shoppingListId: id
        },
        skip: !id || skip
    });

    const lineItems = lineItemMapper(data?.shoppingList?.lineItems, user);
    const name: string | undefined = data?.shoppingList?.name;
    const shoppingListId: string | undefined = data?.shoppingList?.id;
    const shoppingListShareState: ShareState = data?.shoppingList?.shareState
        ? (data?.shoppingList?.shareState as ShareState)
        : ShareState.PRIVATE;
    const shoppingListCreatedBy: string | undefined = data?.shoppingList?.createdBy.contactId;

    return {
        lineItems,
        name,
        shoppingListCreatedBy,
        shoppingListShareState,
        shoppingListId,
        shoppingListError: error,
        shoppingListLoading: loading
    };
};

export default useShoppingList;
