import { gql } from '@apollo/client';

export const PRODUCT_PROMOTION_QUERY = gql`
    query ProductPromotion($skus: [String!]) {
        products(skus: $skus) {
            results {
                id
                slug
                description
                categories
                categoryKey
                version
                relationType
                sku
                productName
                ean
                supplierId
                supplierProductType
                supplierProductLink
                supplierProductNumber
                supplierUnit
                oxomiProductNumber
                image
                images {
                    url
                    label
                }
                procuredProduct
                productCocontractor
                salesUnit
                weight
                packagingSize
                customTariffNumber
                salesNumber
                maxAvailableQuantity
                quantityUnit
                topProduct
                reachInfo
                isTecselect
                isAbakus
                isAbakusPlus
                isPromotion
                isUnqualifiedContractProduct
                onlineAvailable
                customerArticleNumber
                pickupStoreFreiburg {
                    channel {
                        name
                        primaryChannel
                        id
                        key
                        address {
                            id
                            externalId
                            primaryAddress
                            name
                            streetName
                            city
                            postalCode
                            country
                            addressExtraLineOne
                            addressExtraLineTwo
                            addressExtraLineThree
                        }
                    }
                    availability {
                        availableQuantity
                    }
                }
                productDocumentsDeha {
                    name
                    link
                }
                productFeatures {
                    unit
                    featureValueScoped {
                        minValue
                        maxValue
                    }
                    featureValues
                    featureName
                }
            }
        }
    }
`;
