import { gql } from '@apollo/client';

import { MONEY_OBJECT } from './moneyObject';

const CART_PRICE_OBJECT = gql`
    fragment CartPriceFields on CartPriceObject {
        valueWithoutAdditional {
            ...MoneyObject
        }
        vat {
            ...MoneyObject
        }
        vats {
            key
            value {
                ...MoneyObject
            }
        }
        credits {
            ...MoneyObject
        }
        coupon {
            ...MoneyObject
        }
        valueMaterialPriceSurchargesSum {
            ...MoneyObject
        }
        valueSurchargeCutSum {
            ...MoneyObject
        }
        value {
            ...MoneyObject
        }
        shippingCosts {
            ...MoneyObject
        }
        positionPriceObjects {
            basePrice {
                centAmount
            }
            surchargeCut {
                value {
                    ...MoneyObject
                }
            }
        }
        valueWithTaxAndDiscounts {
            ...MoneyObject
        }
    }
    ${MONEY_OBJECT}
`;

export default CART_PRICE_OBJECT;
