import type { FeatureAccess } from '@ab-core/graphql/dist';
import { useFullUser } from '@ab-core/hooks/user/useFullUser';

type AdditionalCheckType<T> = {
    action: AdditionalCheckFunctionType<T>;
    payload: T;
};

export type AdditionalCheckFunctionType<T> = (props: { featureAccess: FeatureAccess; payload?: T }) => boolean;

const userHasPermissions = <T = {}>(
    permission: string,
    isSite = false,
    additionalCheck?: AdditionalCheckType<T>
): boolean => {
    const { user, userLoading } = useFullUser(false);
    let userIsPermitted = false;

    if (!permission && isSite) {
        return true;
    }

    if (userLoading) {
        return !!isSite;
    }

    if (!user) {
        return false;
    }

    if (!user.featureAccess) {
        return false;
    }

    if (user?.userName && permission) {
        userIsPermitted = true;
    }

    const requiredPermissionArray = permission.split(', ');
    const accessArray: Array<FeatureAccess> = [];

    let userHasPermission = false;
    requiredPermissionArray.forEach((permissionString) => {
        const feature = user.featureAccess?.find((featureAccess) => featureAccess?.feature?.name === permissionString);

        if (feature) {
            accessArray.push(feature);
        }

        if (feature?.feature?.value) {
            userHasPermission = true;
        }

        if (feature?.feature?.name === 'hasSessionCarts') {
            userHasPermission = !feature.feature.value;
            userIsPermitted = !feature.feature.value;
        }
    });

    if (userHasPermission) {
        userIsPermitted = true;
    }

    if (permission === 'hasSessionCarts') {
        if (userIsPermitted) {
            localStorage.removeItem('ociSessionId');
        }

        return userIsPermitted;
    }

    if (accessArray.length === 0) {
        return false;
    }

    let accessIsTrue = false;
    accessArray.forEach((access) => {
        if (access?.feature?.value === true) {
            accessIsTrue = true;

            if (additionalCheck) {
                accessIsTrue = additionalCheck.action({ featureAccess: access, payload: additionalCheck.payload });
            }
        }
    });

    if (!accessIsTrue) {
        return false;
    }

    return userIsPermitted;
};

export default userHasPermissions;
