import { ROUTES } from '@ab-core/functions/routes';
import { useSetActiveCart } from '@ab-core/hooks';
import { priceStore } from '@ab-core/store';
import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import orderMapper from '../order/mappers/orderMapper';
import { CREATE_OFFER_ORDER } from '../order/postCreateOfferOrder';

const useOfferOrder = (offerId: string) => {
    const myCredits = priceStore.getters.useCredits();
    const maxCredits = priceStore.getters.useMaxCredits();
    const coupon = priceStore.getters.useCoupon();
    const credits = myCredits > maxCredits ? maxCredits : myCredits;
    const { checkForActiveCart } = useSetActiveCart();
    const [createOfferFromOrder, { loading, error, data }] = useMutation(CREATE_OFFER_ORDER, {
        variables: {
            offerId,
            credits,
            coupon
        },
        onCompleted: async (item) => {
            await checkForActiveCart();

            navigate(ROUTES.CHECKOUTSUCCESS, {
                state: { order: orderMapper(item?.createOrderFromOffer) }
            });
        }
    });

    return {
        createOfferFromOrder,
        offerOrder: orderMapper(data?.createOrderFromOffer),
        offerOrderError: error,
        offerOrderLoading: loading
    };
};

export default useOfferOrder;
