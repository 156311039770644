import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const SearchWrapper = styled.div`
    width: 100%;
    max-width: 520px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: white;
    outline: none;
    border-radius: ${Radius.minimal};
    @media ${BreakpointsMinQuery.xxl} {
        max-width: 1000px;
    }
`;

export const SearchForm = styled.div`
    align-items: center;
    background-color: ${Colors.backgroundShade};
    line-height: 2.5rem;
    height: 100%;
    width: 100%;
    padding: ${Spacing.small3};
    @media ${BreakpointsMinQuery.lg} {
        position: relative;
        padding: ${Spacing.medium} ${Spacing.medium3};
    }

    .selected {
        border: 1px solid ${Colors.primary};
    }
    .not-selected {
        border: 1px solid ${Colors.gray30};
    }
`;

export const SearchInput = styled.input`
    padding-left: 14px;
    border: none;
    height: 100%;
    width: 100%;
    color: ${Colors.black};
    font-size: ${FontTypes.body.fontSize};
    font-weight: ${FontTypes.body.fontWeight};
    caret-color: ${Colors.primary};
    &::placeholder {
        font-size: ${FontTypes.body.fontSize};
        font-weight: ${FontTypes.body.fontWeight};
        color: ${Colors.gray50};
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border: 0;
    }
    @media ${BreakpointsMinQuery.sm} {
        display: inherit;
    }
`;

export const SearchIconContainer = styled.div`
    cursor: pointer;
    margin-right: ${Spacing.small4};
    align-items: center;
    display: flex;
`;
