import { gql } from '@apollo/client';
import { AccountInput } from '@ab-core/graphql/dist';

export type CreateUserSelfOnboardingResponse = {
    createUserSelfOnboarding: AccountInput;
    createUser: boolean;
};
const USER_SELF_ONBOARDING = gql`
    mutation CreateUserSelfOnboarding($accountInput: AccountInput!, $createUser: Boolean) {
        createUserSelfOnboarding(accountInput: $accountInput, createUser: $createUser)
    }
`;

export default USER_SELF_ONBOARDING;
