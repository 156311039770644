import { gql } from '@apollo/client';

const ORDER_DETAILS_QUERY = gql`
    query getOrder($orderNumber: String!) {
        order(orderNumber: $orderNumber) {
            orderHead {
                id
                orderNumber
                costCenter
                project
                orderText
                purchaser
                lastModifiedAt
                orderDate
                orderState
                accountId
                orderType
                orderDeliveryType
                bindingPeriod
                totalPrice {
                    centAmount
                    fractionDigits
                    currencyCode
                }
                deliveryDate
                desiredDeliveryDate
            }
            orderDetailLineItems {
                sku
                lineItemName
                ean
                positionPrice {
                    centAmount
                    fractionDigits
                    currencyCode
                }
                position
                lineItemQuantity
                deliveryQuantity
                invoiceNumber
                deliveryNoteNumber
                state
                positionText
                diverseArticle
                deliveryDate
                estimatedDeliveryDate
                desiredDeliveryDate
                returnValue
                quantityUnit
                salesUnit
            }
            billingAddress {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
            }
            shippingAddress {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
            }
        }
    }
`;

export default ORDER_DETAILS_QUERY;
