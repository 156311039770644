import type { WithBreakpointProps } from '@ab-core/breakpoints';
import { Spacing } from '@ab-core/spacing';
import type { WithTestId } from '@ab-core/testing';
import type React from 'react';

export enum SpacerMapper {
    zero = 0,
    xs = Spacing.small5,
    sm = Spacing.medium2,
    md = Spacing.medium3,
    lg = Spacing.large,
    xl = Spacing.large3,
    xxl = Spacing.large4
}

export type SpacerSizes = 'zero' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type SpacerStyleProps = WithBreakpointProps<SpacerSizes> & { size: SpacerSizes };

export type SpacerProps = WithTestId &
    WithBreakpointProps<SpacerSizes> &
    React.ComponentPropsWithoutRef<'div'> & {
        size?: SpacerSizes;
    };
