import { Spacing } from '@ab-core/spacing';
import styled, { keyframes } from 'styled-components';

const showContainer = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

export const TabPanelItemContainer = styled.div`
    padding-top: ${Spacing.small5};

    &.ab-tab-panel-item-style-type-inactive {
        display: none;
    }
    &.ab-tab-panel-item-style-type-active {
        animation-name: ${showContainer};
        animation-duration: 0.3s;
    }
`;
