import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { DefaultMutationReturnType } from '@ab-core/types/src';
import { useState, useEffect } from 'react';
import { ContactInput } from '@ab-core/graphql/dist';
import INVITE_USER, { InviteUserResponse } from './postInviteUser';

type InviteUserMutationResponseType<T> = DefaultMutationReturnType<T> & {
    inviteUser: (options?: MutationFunctionOptions) => void;
};

type InviteUserReturnType = InviteUserMutationResponseType<InviteUserResponse>;

const InviteUser = (contactInput: ContactInput): InviteUserReturnType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<InviteUserResponse | undefined>(undefined);
    const [pending, setPending] = useState(false);
    const [inviteUser, { loading }] = useMutation<InviteUserResponse>(INVITE_USER, {
        variables: { contactInput },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        inviteUser,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
export default InviteUser;
