import { gql } from '@apollo/client';
import USER_DATA from '../../fragments/userFragment';

export const USER_QUERY = gql`
    query getCustomerData($force: Boolean) {
        getCustomerData(force: $force) {
            ...userFields
        }
    }
    ${USER_DATA}
`;
