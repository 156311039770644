import { BREAKPOINT, BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import Text from '@ab-core/text';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

const one = 1;
const two = 2;
const three = 3;
const four = 4;
const five = 5;
const getBGColor = (level: number) => {
    switch (level) {
        case one:
            return Colors.white;
        case two:
            return Colors.backgroundShade;
        case three:
            return Colors.backgroundShade;
        case four:
            return Colors.white;
        case five:
            return Colors.backgroundShade;
        default:
            return Colors.white;
    }
};
type SiteMenuProps = {
    identifier?: string | null;
    windowWidth?: number;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
    first?: boolean;
};

type ChildType = {
    level: number;
};
type activeType = {
    active?: boolean;
    onClick?: () => void;
};

const PADDING_PER_LEVEL = 10;

const getPaddingLeft = (level: number): string => {
    const padding = level ? PADDING_PER_LEVEL + level * PADDING_PER_LEVEL : 0;

    return `padding-left: ${padding}px`;
};

export const Parent = styled(Text)`
    padding-bottom: 40px;

    display: none;
    @media ${BreakpointsMinQuery.lg} {
        display: block;
    }
`;

export const Child = styled.div<ChildType>`
    background: ${({ level }) => getBGColor(level)};

    @media ${BreakpointsMinQuery.lg} {
        background: ${Colors.white};
        display: flex;
        justify-content: space-between;
        max-width: 286px;
        padding-left: 0;
        padding-right: 40px;
        border: 0;
    }
`;

export const MenuWrapper = styled.div`
    min-width: 100%;

    background: ${Colors.white};
    box-shadow: ${Shadows.navigationShadow};
    @media ${BreakpointsMinQuery.lg} {
        min-height: 750px;
        z-index: ${Z_INDEX.MODAL};
        left: 0;
        position: fixed;
        top: 100px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }
`;

export const StyledMenu = styled.div<SiteMenuProps>`
    max-width: ${BREAKPOINT.XL}px;
    width: 100%;
    @media ${BreakpointsMinQuery.lg} {
        min-height: 750px;
        position: absolute;
        top: 0;
        padding: 40px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
    ${({ first }) => !first && 'left: 20%;'}
`;

export const ChevronIcon = styled.div<activeType>`
    transform: ${({ active }) => (active ? 'rotate(0deg)' : 'rotate(180deg)')};
    padding: 0 16px;
    display: flex;
    align-items: center;
    > div {
        display: flex;
    }
    @media ${BreakpointsMinQuery.lg} {
        transform: rotate(90deg);
    }
`;

export const StyledLink = styled.div<ChildType>`
    padding: ${Spacing.small3};
    ${({ level }) => getPaddingLeft(level)};
    border-bottom: 1px solid ${Colors.gray30};
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media ${BreakpointsMinQuery.lg} {
        padding: 0;
        border-bottom: 0;
    }
`;

export const Title = styled(Text)`
    padding-right: 20px;
    padding-bottom: 8px;
    width: 100%;
    &:hover {
        color: ${Colors.primary};
    }
`;

export const MenuItem = styled.div<ChildType>`
    display: none;

    @media ${BreakpointsMinQuery.lg} {
        ${({ level }) => getPaddingLeft(level)};
        display: flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        padding: 0;
        border-bottom: 0;
    }
`;

export const MenuItemMobile = styled.div<ChildType>`
    padding: ${Spacing.small3};
    ${({ level }) => getPaddingLeft(level)};
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid ${Colors.gray30};
    @media ${BreakpointsMinQuery.lg} {
        display: none;
    }
`;
