import { gql } from '@apollo/client';

export type PostShopAccessResponseType = {
    setShopAccess: boolean;
};

export const UPDATE_SHOP_ACCESS = gql`
    mutation SetShopAccess($shopAccess: Boolean!, $contact: String!) {
        setShopAccess(shopAccess: $shopAccess, contact: $contact)
    }
`;
