import useText from '@ab-core/localization/src/useText';
import { toast } from '@ab-core/toast';
import type { DefaultMutationReturnType } from '@ab-core/types/src';
import type { MutationFunctionOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_COST_CENTERS } from './getCostCenters';
import type { CreateCostCenterResponse } from './postCreateCostCenter';
import { DELETE_COST_CENTER } from './postDeleteCostCenter';

type DeleteCostCenterMutationResponseType<T> = DefaultMutationReturnType<T> & {
    deleteCostCenter: (options?: MutationFunctionOptions) => void;
};

type DeleteCostCenterReturnType = DeleteCostCenterMutationResponseType<CreateCostCenterResponse>;

export const useDeleteCostCenter = (): DeleteCostCenterReturnType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pending, setPending] = useState(false);

    const errorNotificationText = useText('costCenters.errorNotification');

    const [deleteCostCenter, { loading }] = useMutation(DELETE_COST_CENTER, {
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setSuccess(true);
            setPending(false);
            toast.success(`Kostenstelle ${res.deleteCostCenter.name} wurde gelöscht`);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
            toast.error(errorNotificationText);
        },
        refetchQueries: [
            {
                query: GET_COST_CENTERS
            }
        ]
    });
    useEffect(() => {
        if (loading) {
            setPending(true);
        }
    }, [loading]);

    return {
        deleteCostCenter,
        success,
        error,
        errorMessage,
        pending
    };
};
