import type { ReactNode } from 'react';

export enum CARD_STYLE_TYPE {
    FLAT = 'flat',
    CARD = 'card'
}

export type CardType = Omit<React.ComponentPropsWithoutRef<'section'>, 'content'> & {
    head: string;
    content?: ReactNode;
    footer?: ReactNode;
    active?: boolean;
    loading?: boolean;
    pointer?: boolean;
    wordbreak?: boolean;
    styleType?: CARD_STYLE_TYPE;
};
