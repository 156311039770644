import type { CostCenter } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { GET_COST_CENTERS } from './getCostCenters';

type Data = {
    getCostCenters: Array<CostCenter>;
};

type UseCostCentersReturnType = {
    costCenters?: Array<CostCenter>;
    costCentersLoading?: boolean;
};

export const useCostCenters = (): UseCostCentersReturnType => {
    const { data, loading } = useQuery<Data>(GET_COST_CENTERS);

    return {
        costCenters: data?.getCostCenters,
        costCentersLoading: loading
    };
};
