import { gql } from '@apollo/client';
import { OFFER_DETAIL_FRAGMENT } from '../fragments/offerDetailFragment';

const UPDATE_OFFER_POSITIONS = gql`
    mutation UpdateOfferPositions($positionsInput: [OfferPositionUpdateInput!]!, $offerId: String!) {
        updateOfferPositions(positionsInput: $positionsInput, offerId: $offerId) {
            ...OfferDetailFragment
        }
    }
    ${OFFER_DETAIL_FRAGMENT}
`;

export default UPDATE_OFFER_POSITIONS;
