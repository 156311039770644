import type {
    QueryShoppingListsArgs,
    ShoppingList,
    ShoppingListOwner,
    ShoppingListQueryResult
} from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import type { ApolloClient, ApolloError, ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import SHOPPING_LISTS_QUERY from './getShoppingListsQuery';

export type ShoppingListType = {
    id: string;
    name?: string;
    lastModifiedAt: Date;
    slug?: string;
    lineItems?: string[];
    shareState?: string;
    createdBy?: ShoppingListOwner;
};

export type UseShoppingLists = {
    shoppingListsError?: ApolloError;
    shoppingListsLoading?: boolean;
    shoppingLists?: ShoppingListType[];
    myShoppingLists?: ShoppingListType[];
    receivedShoppingLists?: ShoppingListType[];
    client: ApolloClient<unknown>;
    refetch: (_?: QueryShoppingListsArgs) => Promise<ApolloQueryResult<Data>>;
};

type Data = {
    shoppingLists: ShoppingListQueryResult;
};

const useShoppingLists = (sort?: string, skip = false): UseShoppingLists => {
    const { user } = useAuth();
    const { error, data, loading, client, refetch } = useQuery<Data, QueryShoppingListsArgs>(SHOPPING_LISTS_QUERY, {
        skip: skip || false,
        fetchPolicy: 'network-only',
        variables: {
            sort: sort || 'lastModifiedAt desc'
        }
    });

    const mappedData: ShoppingListType[] =
        data?.shoppingLists?.results
            ?.filter((item: ShoppingList) => item.name)
            .map((item: ShoppingList) => ({
                ...item,
                slug: `/liste?name=${item.slug}&id=${item.id}`,
                lastModifiedAt: new Date(item.lastModifiedAt),
                lineItems: item.lineItems as unknown as string[]
            })) || [];

    const sortByParam = (sortBy?: string): ShoppingListType[] | undefined => {
        if (mappedData) {
            const sortedArray: Array<ShoppingListType> = [...mappedData];

            if (sortBy === 'name.DE-de asc') {
                sortedArray?.sort((a, b) => {
                    if ((a.name || '')?.toLowerCase() > (b.name || '')?.toLowerCase()) {
                        return 1;
                    }

                    return -1;
                });
            } else if (sortBy === 'name.DE-de desc') {
                sortedArray?.sort((a, b) => {
                    if ((a.name || '')?.toLowerCase() > (b.name || '')?.toLowerCase()) {
                        return -1;
                    }

                    return 1;
                });
            } else {
                sortedArray?.sort((a, b) => {
                    if (a.lastModifiedAt > b.lastModifiedAt) {
                        return -1;
                    }

                    return 1;
                });
            }

            return sortedArray;
        }

        return undefined;
    };

    const myLists: ShoppingListType[] =
        sortByParam(sort)?.filter(
            (item: ShoppingListType) => user?.contact?.id === item?.createdBy?.contactId || !item?.createdBy?.contactId
        ) || [];

    const receivedLists: ShoppingListType[] =
        sortByParam(sort)?.filter(
            (item: ShoppingListType) => user?.contact?.id !== item?.createdBy?.contactId && item?.createdBy?.contactId
        ) || [];

    return {
        client,
        refetch,
        shoppingListsError: error,
        shoppingListsLoading: loading,
        shoppingLists: sortByParam(),
        myShoppingLists: myLists,
        receivedShoppingLists: receivedLists
    };
};

export default useShoppingLists;
