import { gql } from '@apollo/client';

const DELETE_SHOPPING_LIST = gql`
    mutation deleteShoppingList($id: String!) {
        deleteShoppingList(id: $id) {
            id
        }
    }
`;

export default DELETE_SHOPPING_LIST;
