import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import React, { useEffect, useState } from 'react';
import type { DropdownItemProps } from './components/dropdownItem';
import { DropdownItem } from './components/dropdownItem';
import { DropdownList } from './components/dropdownList';
import { Dropdown, DropdownContainer, IconWrapper, Label, PseudoElementLine } from './styled';

export type BasicDropdownItemProps = Omit<DropdownItemProps, 'onItemSelect'>;

export type BasicDropdownItemsProps = Array<BasicDropdownItemProps>;

export type BasicDropdownProps = React.ComponentPropsWithoutRef<'div'> &
    WithTestId & {
        items?: BasicDropdownItemsProps;
        label?: string;
        selectedValue?: string;
        disabled?: boolean;
        onItemChange?: (item?: BasicDropdownItemProps) => void;
        error?: boolean;
    };

export const BasicDropdown = React.forwardRef<HTMLInputElement, BasicDropdownProps>((props, ref) => {
    const { label, error, disabled, items, selectedValue, onItemChange, onMouseLeave, testId, ...rest } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<BasicDropdownItemProps | undefined>(undefined);

    const setItemActive = (item: BasicDropdownItemProps) => {
        if (item.disabled) {
            return;
        }

        if (item === selectedItem) {
            return;
        }

        setSelectedItem(item);
        setIsOpen(false);

        if (onItemChange) {
            onItemChange(item);
        }
    };

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOnMouseLeave: BasicDropdownProps['onMouseLeave'] = (e) => {
        if (onMouseLeave) {
            onMouseLeave(e);
        }

        setIsOpen(false);
    };

    useEffect(() => {
        const newActiveItem = items?.find((item) => item.value === selectedValue);
        setSelectedItem(newActiveItem);
    }, [selectedValue]);

    const dropdownClasses = getClassNames({
        prefix: 'dropdown',
        modifier: { error, disabled, 'has-selected-item': !!selectedItem }
    });

    const labelClass = getClassNames({
        prefix: 'dropdown-label',
        modifier: { error, disabled }
    });

    const iconClass = getClassNames({
        prefix: 'dropdown-icon',
        modifier: { error, disabled }
    });

    return (
        <DropdownContainer onMouseLeave={handleOnMouseLeave} {...rest} ref={ref}>
            <Dropdown className={dropdownClasses} onClick={toggleDropdown} {...getTestIdProp(testId)}>
                {selectedItem?.label || label}
            </Dropdown>
            {label && selectedItem && (
                <Label className={labelClass}>
                    <PseudoElementLine />
                    {label}
                </Label>
            )}
            <IconWrapper>
                <Icon className={iconClass} name="ChevronUp" size="small3" rotation={isOpen ? 0 : 180} />
            </IconWrapper>
            {isOpen && (
                <DropdownList>
                    {items?.map((item) => (
                        <DropdownItem
                            key={item.id}
                            {...item}
                            isSelected={item.value === selectedItem?.value}
                            onClick={() => setItemActive(item)}
                        />
                    ))}
                </DropdownList>
            )}
        </DropdownContainer>
    );
});
