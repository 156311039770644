import { useMutation } from '@apollo/client';

import { Cart } from '@ab-core/graphql/dist';
import { useState, useEffect } from 'react';
import { UPDATE_SHOPPING_SHARE_STATE } from './updateShoppingShareState';

type ShareShoppingListType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response?: Cart;
    shareShoppingList: () => void;
};

export enum ShareState {
    PUBLIC = 'public',
    SHARED = 'shared',
    PRIVATE = 'private'
}

export const ShareShoppingList = (id: string, shareState?: ShareState): ShareShoppingListType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState(undefined);
    const [pending, setPending] = useState(false);
    const [shareShoppingList, { loading }] = useMutation(UPDATE_SHOPPING_SHARE_STATE, {
        variables: {
            id,
            shareState
        },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        shareShoppingList,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
