import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const BrandWrapper = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    padding: ${Spacing.small4};
    @media ${BreakpointsMinQuery.lg} {
        padding: ${Spacing.medium2};
        width: 228px;
    }
`;

export const BrandLogoContainer = styled.div`
    color: ${Colors.primary};
    display: block;
    height: 20px;
    min-width: 20px;
`;
