import { gql } from '@apollo/client';

export const UPDATE_SHOPPING_SHARE_STATE = gql`
    mutation updateShoppingShareState($id: String!, $shareState: String!) {
        updateShoppingShareState(id: $id, shareState: $shareState) {
            id
            name
            lastModifiedAt
            slug
            shareState
            createdBy {
                contactId
                name
            }
        }
    }
`;
