import { useMutation } from '@apollo/client';

import { Cart } from '@ab-core/graphql/dist';
import { useState, useEffect } from 'react';
import RENAME_SHOPPINGLIST from './updateRenameShoppingList';

type RenameShoppingListType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response?: Cart;
    renameShoppingList: () => void;
};
const RenameShoppingList = (id: string, newName: string): RenameShoppingListType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState(undefined);
    const [pending, setPending] = useState(false);
    const [renameShoppingList, { loading }] = useMutation(RENAME_SHOPPINGLIST, {
        variables: {
            id,
            shoppingListInput: {
                name: newName?.trim()
            }
        },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        renameShoppingList,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
export default RenameShoppingList;
