import type { PromotionalSheetInput } from '@ab-core/graphql/dist';
import type { ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { GET_PROMOTIONAL_TEMPLATE } from './getPromotionalSheets';
import { promotionalTemplateMockData } from './mock/promotionalTemplateMockData';

type PromotionalSheetData = {
    getPromotionalSheets?: Array<PromotionalSheetInput>;
};

export type UsePromotionalTemplate = {
    promotionalTemplate: PromotionalSheetInput;
    loading: boolean;
    refetch?: () => Promise<ApolloQueryResult<PromotionalSheetData>>;
};

export const usePromotionalTemplate = (): UsePromotionalTemplate => {
    const { data, loading, refetch } = useQuery<PromotionalSheetData>(GET_PROMOTIONAL_TEMPLATE, {
        fetchPolicy: 'no-cache'
    });

    const responseData: PromotionalSheetInput | undefined = data?.getPromotionalSheets?.[0];
    const returnObject = {
        headline: responseData?.headline || promotionalTemplateMockData.headline,
        priceColor: responseData?.priceColor || promotionalTemplateMockData.priceColor,
        legalNotice: responseData?.legalNotice || promotionalTemplateMockData.priceColor,
        showPrice: !!responseData?.showPrice,
        showUvp: !!responseData?.showUvp,
        showVat: !!responseData?.showVat,
        footerText: responseData?.footerText || promotionalTemplateMockData.footerText,
        companyImage: responseData?.companyImage || promotionalTemplateMockData.companyImage,
        lastName: responseData?.lastName || promotionalTemplateMockData.lastName,
        firstName: responseData?.firstName || promotionalTemplateMockData.firstName,
        email: responseData?.email || promotionalTemplateMockData.email,
        phoneNumber: responseData?.phoneNumber || promotionalTemplateMockData.phoneNumber,
        showEan: !!responseData?.showEan,
        showProductDescription: !!responseData?.showProductDescription,
        showTechnicalData: !!responseData?.showTechnicalData
    };

    return {
        promotionalTemplate: returnObject,
        loading,
        refetch
    };
};
