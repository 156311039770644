import type { Contact, Customer, FeatureAccess, ShippingAddress } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from './getCustomerData';

type Data = {
    getCustomerData?: Customer[];
};

export type Account = {
    accountId: string;
    accountNumber: string;
    accountName?: string;
    lock?: number;
    businessUnit?: string;
    customerClassification: string;
};

export type User = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fax: string;
    contact?: Contact;
    noSurchargeCutCost: boolean;
    cocontractors: string[];
    address?: ShippingAddress;
    accounts?: Account[];
    activeAccount?: Account;
    userName: string;
    hasAbakus: boolean;
    collectCredits: boolean;
    availableCredits?: number;
    hasShippingCosts: boolean;
    hasForeignVat?: boolean;
    hasPromotionPrice?: boolean;
    responsibleSubsidiary: string;
    deliveryAddresses?: ShippingAddress[];
    featureAccess?: FeatureAccess[];
};

export const useForceCustomerData = (force = false) => {
    const skip = typeof window === 'undefined' || !localStorage.getItem('isLoggedIn');
    const { refetch, client } = useQuery<Data>(USER_QUERY, {
        skip,
        variables: { force },
        fetchPolicy: 'no-cache'
    });

    return {
        refetch,
        client
    };
};
