import type { Mutation, MutationUpdateOfferPositionsArgs } from '@ab-core/graphql/dist';
import { OFFER_QUERY } from '@ab-core/hooks/offer/getOfferQuery';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { UPDATE_OFFER_POSITIONS } from './gql/updateOfferPositions';

type MutationDataType = Pick<Mutation, 'updateOfferPositions'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateOfferPositions');

const refetchQueries = [OFFER_QUERY];

export const useUpdateOfferPositions = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateOfferPositions, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateOfferPositionsArgs
    >(UPDATE_OFFER_POSITIONS, {
        refetchQueries,
        onError
    });

    const outgoingUpdateReturn = useCallback(
        (props: MutationUpdateOfferPositionsArgs) => {
            const { offerId, positionsInput } = props;
            updateOfferPositions({ variables: { offerId, positionsInput } });
        },
        [updateOfferPositions]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateOfferPositions: outgoingUpdateReturn,
        loading,
        response
    };
};
