import { gql } from '@apollo/client';

const RENAME_SHOPPINGLIST = gql`
    mutation renameShoppingList($id: String!, $shoppingListInput: ShoppingListInput!) {
        updateShoppingListName(id: $id, shoppingListInput: $shoppingListInput) {
            name
        }
    }
`;

export default RENAME_SHOPPINGLIST;
