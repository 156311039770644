import { useQuery, ApolloError } from '@apollo/client';
import { Offer, QueryOfferArgs } from '@ab-core/graphql/dist';
import { OFFER_QUERY } from './getOfferQuery';

type Data = {
    offer?: Offer;
};

type ConvertedOffer = {
    offerDate?: Date;
    bindingPeriod?: Date;
} & Omit<Offer, 'offerDate' | 'bindingPeriod'>;

type UseOffersType = {
    offer?: ConvertedOffer;
    offerError?: ApolloError;
    offerLoading: boolean;
};

const useOfferDetail = (offerNumber: string): UseOffersType => {
    if (!offerNumber) {
        offerNumber = '';
    }
    const { error, data, loading } = useQuery<Data, QueryOfferArgs>(OFFER_QUERY, {
        skip: !offerNumber,
        variables: {
            offerNumber
        }
    });

    const offerDetail = data?.offer
        ? {
              ...data?.offer,
              ...{
                  offerDate: data?.offer?.offerDate ? new Date(data?.offer.offerDate) : undefined,
                  bindingPeriod: data?.offer?.bindingPeriod ? new Date(data?.offer.bindingPeriod) : undefined
              }
          }
        : undefined;

    return {
        offer: offerDetail,
        offerError: error,
        offerLoading: loading
    };
};

export default useOfferDetail;
