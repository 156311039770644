import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
    query GetContacts {
        getContacts {
            id
            salutation
            userName
            firstName
            lastName
            email
            onlineShopAccess
            fax
            title
            createdAt
            academicTitle
            function
            mobilePhone
            phone
            featureAccess {
                id
                feature {
                    id
                    name
                    value
                    amountValue
                    valueText
                }
            }
        }
    }
`;
