import { Colors, Shadows } from '@ab-core//colors';
import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

type WrapperType = {
    topMargin?: number;
    width: string;
    offsetLeft: string;
};

export const Wrapper = styled.div<WrapperType>`
    position: fixed;
    z-index: ${Z_INDEX.MODAL_BACKDROP};
    top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : '56px')};
    padding: ${Spacing.small4} ${Spacing.small};
    @media ${BreakpointsMinQuery.lg} {
        top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : '100px')};
        padding: ${Spacing.medium2} ${Spacing.small4};
    }
    border-top: 1px solid ${Colors.gray30};

    background: ${Colors.white};
    box-shadow: ${Shadows.navigationShadow};
    border-radius: 0px 0px ${Radius.full} ${Radius.full};
    width: ${({ width }) => width};
    left: ${({ offsetLeft }) => offsetLeft};
`;
