import { useAuth } from '@ab-core/hooks';
import { featureAccessList } from './featureAccessList';

const useIsFeatureVisible = (featureName: string, isSite = false): boolean => {
    const { user, userLoading } = useAuth();
    const accountId = user?.activeAccount?.accountId || '';
    const customerId = user?.activeAccount?.accountNumber;

    if (!featureName) {
        return true;
    }

    if (userLoading) {
        return !!isSite;
    }

    if (!accountId || !customerId) {
        return false;
    }

    if (featureName) {
        return Boolean(
            featureAccessList[featureName as keyof typeof featureAccessList]?.find(
                (access) =>
                    (access.type === 'account' && access.id === accountId) ||
                    (access.type === 'customer' && access.id === customerId)
            )
        );
    }

    return true;
};

export default useIsFeatureVisible;
