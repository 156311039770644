import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const ElementWrapper = styled.div`
    display: flex;
    gap: ${Spacing.small2};
    padding: ${Spacing.small5};
    justify-content: center;
    align-items: center;
    width: auto;
    cursor: pointer;
    @media ${BreakpointsMinQuery.lg} {
        padding: ${Spacing.medium2};
    }
`;
