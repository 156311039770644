import { gql } from '@apollo/client';

const SHOPPING_LIST_FRAGMENT = gql`
    fragment shoppingListFields on ShoppingList {
        id
        name
        lastModifiedAt
        slug
        shareState
        createdBy {
            contactId
            name
        }
        lineItems {
            id
        }
    }
`;

export default SHOPPING_LIST_FRAGMENT;
