import type { Mutation, MutationDeleteShoppingListLineItemArgs, ShoppingList } from '@ab-core/graphql/dist';
import type { MutationFunctionOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import DELETE_FROM_SHOPPINGLIST from './deleteFromShoppingList';
import SHOPPING_LIST_QUERY from './getShoppingListQuery';

type DeleteLineItemFromShoppingListType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response?: ShoppingList;
    deleteLineItemFromShoppingList: (
        options?: MutationFunctionOptions<
            Mutation['deleteShoppingListLineItem'],
            MutationDeleteShoppingListLineItemArgs
        >
    ) => void;
};
const DeleteLineItemFromShoppingList = (
    shoppingListId: string,
    lineItemId: string
): DeleteLineItemFromShoppingListType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState(undefined);
    const [pending, setPending] = useState(false);
    const [deleteLineItemFromShoppingList, { loading }] = useMutation(DELETE_FROM_SHOPPINGLIST, {
        variables: { id: shoppingListId, lineItemId },
        refetchQueries: [{ query: SHOPPING_LIST_QUERY, variables: { shoppingListId } }],
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);

    return {
        deleteLineItemFromShoppingList,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};

export default DeleteLineItemFromShoppingList;
