import { formatNumber } from '@ab-core/functions/formatting/formatNumber';
import type { BasicTracking } from '@ab-core/functions/tracking/types';
import type { Product, QueryGetProductBySkuArgs } from '@ab-core/graphql/dist';
import { PRODUCT_PAGE_QUERY, useAuth } from '@ab-core/hooks';
import type { User } from '@ab-core/hooks/user/useAuth';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';

type Data = {
    getProductBySku: Product;
};

export const relatedProductGroupNames: Array<string> = [
    'alternativesEnventa',
    'similarProducts',
    'accessoriesProducts',
    'baseProducts',
    'necessaryAccessories',
    'necessarySelections',
    'parts',
    'spareParts',
    'replacments'
];

const WEIGHT_DIGITS = 3;

export const isUnqualifiedContractProduct = (productCocontractor: string, user?: User): boolean => {
    if (productCocontractor) {
        if (user && user.cocontractors) {
            return !user.cocontractors.includes(productCocontractor.replaceAll('"', ''));
        }

        return true;
    }

    return false;
};

export type ProductGroupsType = {
    [key: string]: Array<Product>;
};

export type ProductWithInfo = Product & { infos?: Array<InfoType> };

type InfoType = { name: string; value: string };

type useProductReturnType = {
    product: ProductWithInfo;
    productLoading: boolean;
    productError?: ApolloError;
    relatedProducts?: ProductGroupsType;
    refetch: (_: QueryGetProductBySkuArgs) => Promise<ApolloQueryResult<Data>>;
};

const formatWeightString = (weight?: number) => {
    return Number.isInteger(weight) ? weight : weight?.toFixed(WEIGHT_DIGITS).replace('.', ',') || '';
};

export const generateRelatedProductsObject = (relatedProduct: ProductWithInfo) => {
    const obj: { [key: string]: Array<Product> } = {};
    relatedProductGroupNames?.map((groupName: string) => {
        obj[groupName] = [...(relatedProduct[groupName as keyof typeof relatedProduct] as Array<Product>)];
    });

    return obj;
};

const useProduct = (id: string, skip = false, tracking?: BasicTracking): useProductReturnType => {
    const { user } = useAuth();
    const { loading, error, data, refetch } = useQuery<Data, QueryGetProductBySkuArgs>(PRODUCT_PAGE_QUERY, {
        variables: {
            sku: id,
            tracking
        },
        skip
    });

    let product: ProductWithInfo | undefined;
    let relatedProducts;

    if (data?.getProductBySku) {
        const weightString = (data?.getProductBySku?.weight && formatWeightString(data?.getProductBySku?.weight)) || '';
        product = {
            ...data?.getProductBySku,
            isUnqualifiedContractProduct: isUnqualifiedContractProduct(
                data?.getProductBySku?.productCocontractor as string,
                user
            ),
            infos: [
                { name: 'Lieferanten-Nr.', value: data?.getProductBySku?.supplierProductNumber || '' },
                { name: 'Zolltarif-Nr.', value: data?.getProductBySku?.customTariffNumber?.toString() || '' },
                { name: 'Verkaufseinheit', value: formatNumber(data?.getProductBySku?.salesUnit) },
                { name: 'Verpackungseinheit', value: formatNumber(data?.getProductBySku?.packagingSize) },
                { name: 'Mengeneinheit', value: data?.getProductBySku?.quantityUnit || '' },
                {
                    name: 'Gewicht',
                    value:
                        (data?.getProductBySku?.weight &&
                            `${weightString} kg ${
                                data?.getProductBySku?.quantityUnit ? '/ ' + data?.getProductBySku?.quantityUnit : ''
                            }`) ||
                        ''
                }
            ]
        };

        relatedProducts = generateRelatedProductsObject(product);
    }

    return {
        productLoading: loading,
        productError: error,
        product,
        relatedProducts,
        refetch
    };
};

export default useProduct;
