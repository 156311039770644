import { BUTTON_VARIANT } from '@ab-core/button';
import { ROUTES } from '@ab-core/functions';
import { HorizontalLine } from '@ab-core/horizontalline';
import useText from '@ab-core/localization/src/useText';
import Spacer from '@ab-core/spacer';
import { navigationStore } from '@ab-core/store';
import { navigate } from 'gatsby';
import React from 'react';
import { TextButtonBox } from '../TextButtonBox';

export const Register = () => {
    const handleSignUp = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setUserDropdownNavigation(false);
        navigate(ROUTES.SIGNUP);
    };

    const handleRequestAccess = () => {
        navigationStore.setters.setUserNavigation(false);
        navigationStore.setters.setUserDropdownNavigation(false);
        navigate(ROUTES.REQUESTACCESS);
    };

    const headlineText = useText('loginBox.requestAccessHeadline');
    const text = useText('loginBox.requestAccessText');
    const buttonText = useText('loginBox.requestAccessButtonText');

    const secondaryHeadlineText = useText('loginBox.signUpHeadline');
    const secondaryText = useText('loginBox.signUpText');
    const secondaryButtonText = useText('loginBox.signUpButtonText');

    return (
        <>
            <TextButtonBox
                headlineText={headlineText}
                text={text}
                buttonText={buttonText}
                handleClick={handleRequestAccess}
                variant={BUTTON_VARIANT.FILL}
            />

            <Spacer size="sm" />
            <HorizontalLine />
            <Spacer size="sm" />

            <TextButtonBox
                headlineText={secondaryHeadlineText}
                text={secondaryText}
                buttonText={secondaryButtonText}
                handleClick={handleSignUp}
                variant={BUTTON_VARIANT.OUTLINE}
            />

            <Spacer />
        </>
    );
};
