import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const NavigationElementWrapper = styled.div`
    &.ab-shop-navigation {
        padding: ${Spacing.small3};
        display: flex;
        gap: ${Spacing.medium2};
        height: 48px;
        cursor: pointer;
        align-items: center;
        border-left: 4px solid ${Colors.white};
        @media ${BreakpointsMinQuery.md} {
            padding: ${Spacing.small3} ${Spacing.medium2};
        }
        &.ab-shop-navigation:hover {
            background-color: ${Colors.backgroundShade};
            border-color: ${Colors.backgroundShade};
        }
        &.ab-shop-navigation-isActive {
            background-color: ${Colors.backgroundShade};
            border-color: ${Colors.primary};
        }
    }
`;
