import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const HeadlineWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${Spacing.small3};
`;

export const IconButton = styled.div`
    cursor: pointer;
    padding: ${Spacing.small2};
`;
