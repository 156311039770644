import { gql } from '@apollo/client';
import ACCOUNT_DATA from '../../fragments/accountFragment';

const GET_ACCOUNTS = gql`
    query GetAccounts {
        getAccounts {
            ...accountFields
        }
    }
    ${ACCOUNT_DATA}
`;

export default GET_ACCOUNTS;
