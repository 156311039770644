import { gql } from '@apollo/client';

export const UPDATE_SHOPPING_LIST_LINE_ITEM = gql`
    mutation UpdateShoppingListLineItem($lineItemInput: ShoppingListLineItemInput!, $shoppingListId: String!) {
        updateShoppingListLineItem(lineItemInput: $lineItemInput, id: $shoppingListId) {
            id
            lineItems {
                id
                productName
            }
        }
    }
`;
