import { BREAKPOINT } from '@ab-core/breakpoints';
import { useDebounce } from '@ab-core/debouncer';
import { DropdownOverlay } from '@ab-core/dropdownoverlay';
import { ROUTES, useIsMobile, useOnClickOutside } from '@ab-core/functions';
import {
    getTrackingSearch,
    getTrackingSuggest,
    setAdditionalTrackingObject,
    setAndGetRequestId,
    useTracking
} from '@ab-core/functions/tracking';
import type { BasicTracking } from '@ab-core/functions/tracking/types';
import { isLoggedIn } from '@ab-core/hooks';
import { useSearchSuggest } from '@ab-core/hooks-v2';
import { Icon } from '@ab-core/icon';
import { getTestIdProp } from '@ab-core/testing';
import { getTutorialIdProp } from '@ab-core/tutorials';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { SearchSuggestions } from './components/suggestions';
import { SearchForm, SearchIconContainer, SearchInput, SearchWrapper } from './styled';
import { searchInputFocusExceptions } from './utils';

type SearchType = {
    searchString?: string;
};

export const Search: FC<SearchType> = ({ searchString }) => {
    const search = searchString;
    const [value, setValue] = useState<string | undefined>(search);
    const [suggest, setSuggest] = useState<string | undefined>();
    const [activeSuggest, setActiveSuggest] = useState<number | undefined>(undefined);
    const [selected, setSelected] = useState(false);
    const [trackingSuggestProps, setTrackingSuggestProps] = useState<BasicTracking | undefined>(undefined);
    const [suggestionsShouldDisplay, setSuggestionsShouldDisplay] = useState(false);
    const { suggestions: suggestResult } = useSearchSuggest({ query: suggest, tracking: trackingSuggestProps });
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const isUserLoggedIn = isLoggedIn();

    const searchRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLInputElement>(null);
    const suggestRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([suggestRef, searchRef], () => {
        setSuggestionsShouldDisplay(false);
    });

    const { handleDebounce, cancelDebounce } = useDebounce();

    const defaultSuggestProps = useTracking(
        getTrackingSuggest({
            q: value,
            position: activeSuggest
        })
    );

    const setSelectedState = () => {
        const isUnfocusRoute = searchInputFocusExceptions.some((route) => {
            return location?.pathname.includes(route.valueOf());
        });

        if (!isMobile && isUserLoggedIn && !isUnfocusRoute) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    };

    useEffect(() => {
        setSelectedState();
    }, [location?.pathname, isMobile]);

    useEffect(() => {
        if (!selected) {
            searchRef?.current?.blur();

            return;
        }

        if (!isMobile) {
            searchRef?.current?.focus();
        }
    }, [selected]);

    useEffect(() => {
        if (trackingSuggestProps !== undefined) {
            setTrackingSuggestProps(undefined);
        }
    }, [suggestResult]);

    useEffect(() => {
        if (typeof window === 'undefined' || !window.location.pathname.includes('suche')) {
            return;
        }

        setValue(search);
    }, [search]);

    window.addEventListener('focus', () => {
        setSelectedState();
    });

    const submitSearch = (val = value || '') => {
        cancelDebounce();

        if (val && defaultSuggestProps && activeSuggest) {
            const requestId = setAndGetRequestId();
            defaultSuggestProps.selectedQuery = val;
            setTrackingSuggestProps({ ...defaultSuggestProps, requestId });
        }

        const searchTracking = getTrackingSearch({ q: val });
        setAdditionalTrackingObject(searchTracking);

        if (val) {
            setValue(val);
        }

        setSuggestionsShouldDisplay(false);

        setActiveSuggest(undefined);

        const encodedValue = encodeURIComponent(val);
        navigate(`${ROUTES.SEARCH}?q=${encodedValue}`);
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        setValue(inputValue);
        handleDebounce(() => {
            setSuggest(inputValue);
            setSuggestionsShouldDisplay(true);
        });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            if (typeof activeSuggest === 'undefined') {
                setActiveSuggest(0);
            } else {
                setActiveSuggest(Math.min(activeSuggest + 1, (suggestResult?.length || 1) - 1));
            }
        }

        if (e.key === 'ArrowUp') {
            if (typeof activeSuggest === 'undefined') {
                setActiveSuggest(0);
            } else {
                setActiveSuggest(Math.max(activeSuggest - 1, 0));
            }
        }

        if (e.key === 'Enter') {
            e.currentTarget.blur();
            submitSearch(value);
        }

        if (e.key === 'Escape') {
            setActiveSuggest(undefined);
            setSuggestionsShouldDisplay(false);
        }
    };

    useEffect(() => {
        if (typeof activeSuggest === 'number' && suggestResult) {
            setValue(suggestResult[activeSuggest].suggest);
        }
    }, [activeSuggest]);

    const handleClear = () => {
        setValue('');
        setSuggestionsShouldDisplay(false);
        searchRef?.current?.focus();
    };

    const onSearchClick = () => {
        setActiveSuggest(undefined);
        submitSearch(value);
    };

    return (
        <>
            <SearchForm ref={wrapperRef}>
                <SearchWrapper
                    className={selected ? 'selected' : 'not-selected'}
                    {...getTutorialIdProp('search-input')}
                >
                    <SearchInput
                        {...getTestIdProp('search-field')}
                        ref={searchRef}
                        enterKeyHint="enter"
                        onKeyDown={handleKeyPress}
                        onChange={handleChange}
                        onClick={() => setSuggestionsShouldDisplay(true)}
                        value={value}
                        autoFocus={false}
                        type="text"
                        placeholder="Schlagwort / EAN / Artikelnummer"
                        onFocus={() => setSelected(true)}
                        onBlur={() => setSelected(false)}
                    />
                    {value && value?.length > 0 && (
                        <SearchIconContainer onClick={handleClear}>
                            <Icon name="Close" color="primary" />
                        </SearchIconContainer>
                    )}
                    <SearchIconContainer onClick={onSearchClick} {...getTestIdProp('submit-search-button')}>
                        <Icon name="Search" />
                    </SearchIconContainer>
                </SearchWrapper>
                <DropdownOverlay
                    parentRef={wrapperRef}
                    open={Boolean(suggestionsShouldDisplay && suggestResult)}
                    onClose={() => {}}
                >
                    <SearchSuggestions
                        suggestResult={suggestResult}
                        ref={suggestRef}
                        activeSuggest={activeSuggest}
                        submitSearch={submitSearch}
                    />
                </DropdownOverlay>
            </SearchForm>
        </>
    );
};
