import { gql } from '@apollo/client';
import SHOPPING_LIST_FRAGMENT from '../fragments/shoppingListFragment';

const SHOPPING_LISTS_QUERY = gql`
    query shoppingLists($sort: String) {
        shoppingLists(sort: $sort) {
            currentPage
            pageCount
            results {
                ...shoppingListFields
            }
        }
    }
    ${SHOPPING_LIST_FRAGMENT}
`;
export default SHOPPING_LISTS_QUERY;
