import { ShoppingList } from '@ab-core/graphql/dist';
import { gql } from '@apollo/client';
export type UpdateShoppingListResponseType = {
    upsertShoppingListLineItem: Array<ShoppingList>;
};
const ADD_TO_SHOPPINGLIST = gql`
    mutation addToShoppingList($ids: [String!]!, $lineItemInput: [ShoppingListLineItemInput!]!) {
        upsertShoppingListLineItem(ids: $ids, lineItemInput: $lineItemInput) {
            id
            name
            slug
        }
    }
`;

export default ADD_TO_SHOPPINGLIST;
