import { gql } from '@apollo/client';

export const CART_OBJECT = gql`
    fragment ABCartFields on Cart {
        id
        version
        name
        isActiveCart
        orderText
        costCenter
        singleInv
        priceDN
        sentFrom
        shippingAddress {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
        }
        deliveryDate
        lastModifiedAt
        completeDelivery
        shippingInstructions
        displayShippingMethod
        cartProjectNr
        shareState
        createdBy {
            contactId
            name
        }
        shippingInfo {
            shippingMethod {
                key
            }
        }
        custom {
            customFieldsRaw {
                name
                value
            }
        }
        customLineItems {
            ean
            id
            name
            positionText
            addedBy {
                contactId
                name
            }
            quantity
            quantityUnit
            supplierName
            supplierProductNumber
        }
        lineItems {
            isProductOnlineAvailable
            isUnqualifiedContractProduct
            positionText
            sku
            ean
            salesUnit
            name
            quantityUnit
            procuredProduct
            image
            quantity
            chargeNr
            id
            addedBy {
                contactId
                name
            }
            deliveryFromFactory
            reach
            supplierId
            supplierName
            productCocontractorValue
            category
            categories {
                id
                name
            }
            supplierProductNumber
            isAbakus
            isAbakusPlus
            isTecselect
            hasAlternatives
            hasAccessories
            custom {
                customFieldsRaw {
                    name
                    value
                }
            }
            variant {
                sku
                images {
                    url
                }
                attributesRaw {
                    name
                    value
                }
            }
        }
    }
`;
