import type { Cart, Mutation, MutationDeleteShoppingListArgs } from '@ab-core/graphql/dist';
import type { MutationFunctionOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import DELETE_SHOPPING_LIST from './deleteShoppingList';
import SHOPPING_LISTS_QUERY from './getShoppingListsQuery';

type DeleteShoppingListType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response?: Cart;
    deleteShoppingList: (
        options?: MutationFunctionOptions<Mutation['deleteShoppingList'], MutationDeleteShoppingListArgs>
    ) => void;
};
const DeleteShoppingList = (id: string): DeleteShoppingListType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState(undefined);
    const [pending, setPending] = useState(false);
    const [deleteShoppingList, { loading }] = useMutation(DELETE_SHOPPING_LIST, {
        variables: { id },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        },
        refetchQueries: [
            {
                query: SHOPPING_LISTS_QUERY
            }
        ]
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);

    return {
        deleteShoppingList,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};

export default DeleteShoppingList;
