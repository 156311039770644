import { useMutation } from '@apollo/client';

import { useState, useEffect } from 'react';
import { RENEW_OFFER } from './renewOfferMutatuon';

type RenewOfferType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;

    renewOffer: () => void;
};
const RenewOffer = (offerNumber: string): RenewOfferType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [pending, setPending] = useState(false);
    const [renewOffer, { loading }] = useMutation(RENEW_OFFER, {
        variables: { offerNumber },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: () => {
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        renewOffer,
        success,
        error,
        errorMessage,
        pending
    };
};
export default RenewOffer;
