import type { Cart } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import { useSessionCart } from '@ab-core/hooks/user/useSessionCart';
import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { MappedCart } from '../mappers/cartMapper';
import cartMapper from '../mappers/cartMapper';
import { ACTIVECART } from './activeCart';

type GetActiveCartType = {
    cartCount: number;
    loading: boolean;
    error?: ApolloError;
    activeCart?: MappedCart;
    activeCartRefetch: () => Promise<Cart | undefined>;
};
type Data = {
    getActiveCart?: Cart;
};
const getActiveCart = (skip = false): GetActiveCartType => {
    let tempSkip = skip;

    const { user, userLoading } = useAuth();

    if (!skip && !user && !userLoading) {
        tempSkip = true;
    }

    const { checkForSessionCart } = useSessionCart();
    const { ociSessionId } = checkForSessionCart();
    const { error, data, loading, refetch } = useQuery<Data>(ACTIVECART, {
        fetchPolicy: 'network-only',
        skip: tempSkip,
        variables: { sessionId: ociSessionId }
    });
    const activeCart = data?.getActiveCart;
    let mappedActiveCart;
    let cartCount = 0;

    if (activeCart) {
        mappedActiveCart = cartMapper(activeCart);
        cartCount = (activeCart?.lineItems?.length || 0) + (activeCart?.customLineItems?.length || 0);
    }

    const activeCartRefetch = async () => {
        if (tempSkip) {
            return undefined;
        }

        const refetchedResult = await refetch();
        const refetchedActiveCart = refetchedResult.data?.getActiveCart;

        return refetchedActiveCart || undefined;
    };

    return {
        activeCart: mappedActiveCart,
        cartCount,
        activeCartRefetch,
        error,
        loading
    };
};

export default getActiveCart;
