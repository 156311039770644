import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import Text from '@ab-core/text';
import styled from 'styled-components';

type SuggestionType = {
    active?: boolean;
    onMouseEnter?: () => void;
};

export const Suggestions = styled.div`
    overflow-y: auto;
    max-height: 360px;
    margin: 0;
    width: 100%;
    padding-top: ${Spacing.small4};
    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: ${Radius.minimal};
    }

    ::-webkit-scrollbar-thumb {
        background: ${Colors.primary};
        border-radius: ${Radius.minimal};
    }
    @media ${BreakpointsMinQuery.sm} {
        margin: 0;
        max-height: 560px;
    }
    @media ${BreakpointsMinQuery.md} {
        margin: 0;
        max-height: 860px;
    }
`;

export const Suggestion = styled.button<SuggestionType>`
    padding: ${Spacing.small2};
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    outline: none;
    background-color: ${({ active }) => (active ? Colors.backgroundShade : Colors.white)};
    &:hover {
        background-color: ${Colors.backgroundShade};
    }
    border: 0;
    border-radius: ${Radius.rounded};
`;

export const SuggestionText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    padding-left: 20px;
    & b {
        font-weight: 700;
    }
`;
