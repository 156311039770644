import { gql } from '@apollo/client';
import { OFFER_DETAIL_FRAGMENT } from '../fragments/offerDetailFragment';

export const OFFER_QUERY = gql`
    query offer($offerNumber: String!) {
        offer(offerNumber: $offerNumber) {
            ...OfferDetailFragment
        }
    }
    ${OFFER_DETAIL_FRAGMENT}
`;
