import { gql } from '@apollo/client';

export type CreateCostCenterResponse = {
    createCostCenter: {
        name: string;
        description: string;
    };
};

export const CREATE_COST_CENTER = gql`
    mutation CreateCostCenter($data: CostCenterCreationInput!) {
        createCostCenter(data: $data) {
            name
        }
    }
`;
