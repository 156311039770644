import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const Block = styled.div`
    display: block;
    padding-top: ${Spacing.small};
`;

export const SiteMenuContainer = styled.div`
    width: 80vw;
`;
