import { gql } from '@apollo/client';
import SHOPPING_LIST_LINEITEM from '../fragments/shoppingListLineItemFragment';

const DELETE_FROM_SHOPPINGLIST = gql`
    mutation deleteFromList($id: String!, $lineItemId: String!) {
        deleteShoppingListLineItem(id: $id, lineItemId: $lineItemId) {
            id
            name
            slug
            lastModifiedAt
            lineItems {
                ...shoppingListLineitem
            }
        }
    }
    ${SHOPPING_LIST_LINEITEM}
`;

export default DELETE_FROM_SHOPPINGLIST;
