import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const SheetContent = styled.div`
    padding: ${Spacing.small2} 0;
    @media ${BreakpointsMinQuery.lg} {
        padding: ${Spacing.small5} 0;
    }
`;

export const StyledSheet = styled.section`
    &.ab-sheet-styleType-flat {
        border: 1px solid ${Colors.gray30};
    }
    &.ab-sheet-styleType-card {
        box-shadow: ${Shadows.componentShadow};
    }
    width: 100%;
    position: relative;
    background-color: ${Colors.white};
    padding: ${Spacing.small4};
    @media ${BreakpointsMinQuery.lg} {
        padding: ${Spacing.medium};
    }
    border-radius: ${Radius.rounded};
    margin: ${Spacing.small2} 0;
    &.ab-sheet-active {
        border-left: 6px solid ${Colors.primary};
    }
`;
