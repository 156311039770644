import { gql } from '@apollo/client';

export type InviteUserResponse = {
    inviteUser: boolean;
};

const INVITE_USER = gql`
    mutation inviteUser($contactInput: ContactInput!) {
        inviteUser(contactInput: $contactInput)
    }
`;

export default INVITE_USER;
