import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const InlineWrapper = styled.div`
    margin-top: ${Spacing.small};
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: ${Spacing.small3};
    min-height: ${Spacing.small4};
`;
