import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export const CARTS = gql`
    query getMyCarts($sessionId: String) {
        getMyCarts(sessionId: $sessionId) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
