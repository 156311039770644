import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { FC } from 'react';
import React from 'react';
import { StyledTableRow } from './styled';

export enum TABLE_STYLE_TYPE {
    FLAT = 'flat',
    CARD = 'card'
}
type TableStyle = {
    styleType?: TABLE_STYLE_TYPE;
};
type TableRowProps = TableStyle & React.ComponentPropsWithoutRef<'tr'>;

export const TableRow: FC<TableRowProps> = (props) => {
    const { styleType = TABLE_STYLE_TYPE.CARD, className } = props;
    const tableBodyRowClass = getClassNames({
        className,
        prefix: 'table-row',
        modifier: { styleType }
    });

    return <StyledTableRow className={tableBodyRowClass} {...props} />;
};
