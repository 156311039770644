import { ROUTES } from '@ab-core/functions/routes';
import { useSetActiveCart } from '@ab-core/hooks';
import { useForceCustomerData } from '@ab-core/hooks/user/customerData/useForceCustomerData';
import { USER_QUERY } from '@ab-core/hooks/user/getUserQuery';
import { priceStore } from '@ab-core/store';
import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import orderMapper from './mappers/orderMapper';
import { CREATE_ORDER } from './postCreateOrder';

const useOrder = (activeCartId: string) => {
    const myCredits = priceStore.getters.useCredits();
    const maxCredits = priceStore.getters.useMaxCredits();
    const coupon = priceStore.getters.useCoupon();
    const { checkForActiveCart } = useSetActiveCart();
    const { refetch: refetchData } = useForceCustomerData();
    const credits = myCredits > maxCredits ? maxCredits : myCredits;
    const [createOrder, { client, loading, error, data }] = useMutation(CREATE_ORDER, {
        variables: {
            cartId: activeCartId,
            credits,
            coupon
        },
        fetchPolicy: 'network-only',
        onCompleted: async (item) => {
            await checkForActiveCart();
            refetchData({ force: true }).then(() => {
                client.resetStore();
                navigate(ROUTES.CHECKOUTSUCCESS, {
                    state: { order: orderMapper(item?.createMyOrder) }
                });
            });
        },
        refetchQueries: [
            {
                query: USER_QUERY,
                fetchPolicy: 'no-cache',
                variables: {}
            },
            'GetCoupons'
        ],
        update(cache) {
            cache.modify({
                id: cache.identify({ __typename: 'Cart', id: activeCartId }),
                fields: {
                    cart(_existingCommentRefs, { DELETE }) {
                        return DELETE;
                    }
                }
            });
        }
    });

    return { createOrder, order: orderMapper(data?.createMyOrder), orderError: error, orderLoading: loading };
};

export default useOrder;
