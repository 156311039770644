import { gql } from '@apollo/client';

const SHOPPING_LIST_LINEITEM = gql`
    fragment shoppingListLineitem on ShoppingListLineItem {
        id
        sku
        quantity
        image
        productName
        ean
        supplierId
        supplierName
        productCocontractorValue
        productCocontractor
        procuredProduct
        salesUnit
        quantityUnit
        isTecselect
        isAbakusPlus
        customerArticleNumber
        hasAccessories
        hasAlternatives
        promoProduct
        scoreCardIndex
    }
`;

export default SHOPPING_LIST_LINEITEM;
