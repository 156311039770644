import { gql } from '@apollo/client';
import PRODUCT_PAGE_DATA from '../fragments/productPageData';

const PRODUCT_PAGE_QUERY = gql`
    query ProductPage($sku: String!, $tracking: Tracking) {
        getProductBySku(sku: $sku, tracking: $tracking) {
            ...productPageFields
            alternativesEnventa {
                ...productPageFields
            }
            similarProducts {
                ...productPageFields
            }
            baseProducts {
                ...productPageFields
            }
            necessaryAccessories {
                ...productPageFields
            }
            necessarySelections {
                ...productPageFields
            }
            parts {
                ...productPageFields
            }
            accessoriesProducts {
                ...productPageFields
            }
            spareParts {
                ...productPageFields
            }
            replacments {
                ...productPageFields
            }
        }
    }
    ${PRODUCT_PAGE_DATA}
`;

export default PRODUCT_PAGE_QUERY;
