import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const TabListItemContainer = styled.div`
    display: flex;
    width: auto;
    gap: ${Spacing.small4};
    justify-content: center;
    align-items: center;
    padding: ${Spacing.small};
    border-bottom: 2px solid transparent;
    transition: all 0.3s linear;
    cursor: pointer;

    &.ab-tab-list-item-style-type-inactive {
        color: ${Colors.gray70};
        &:hover {
            color: ${Colors.primaryDarkenOne};
        }
        &:active {
            color: ${Colors.primaryDarkenTwo};
        }
    }
    &.ab-tab-list-item-style-type-disabled {
        cursor: not-allowed;
        color: ${Colors.gray50};
    }
    &.ab-tab-list-item-style-type-active {
        color: ${Colors.primary};
        border-bottom: 2px solid ${Colors.primary};
    }
`;
