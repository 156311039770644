import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { DefaultMutationReturnType } from '@ab-core/types/src';
import { useState, useEffect } from 'react';
import { PostShopAccessResponseType, UPDATE_SHOP_ACCESS } from './updateShopAccess';

type PostShopAccessMutationResponseType<T> = DefaultMutationReturnType<T> & {
    updateShopAccess: (options?: MutationFunctionOptions) => void;
};

type PostShopAccessReturnType = PostShopAccessMutationResponseType<PostShopAccessResponseType>;

const PostShopAccess = (access = false, id?: string): PostShopAccessReturnType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<PostShopAccessResponseType | undefined>(undefined);
    const [pending, setPending] = useState(false);
    const [updateShopAccess, { loading }] = useMutation<PostShopAccessResponseType>(UPDATE_SHOP_ACCESS, {
        fetchPolicy: 'no-cache',
        variables: { shopAccess: access, contact: id },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        updateShopAccess,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
export default PostShopAccess;
