import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Icon } from '@ab-core/icon';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

type ModalInnerType = {
    footer?: boolean;
    noPadding?: boolean;
    height?: number;
};

type ContentType = {
    height?: number;
};

type HeaderProps = {
    useFullWidth?: boolean;
};

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.MODAL};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    @media ${BreakpointsMinQuery.sm} {
        justify-content: center;
    }
    .content {
        width: 100%;
        z-index: ${Z_INDEX.MODAL};
        display: flex;
        justify-content: center;
    }
    .backdrop {
        background-color: ${Colors.black};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0.5;
        pointer-events: none;
    }
`;

export const Content = styled.div<ContentType>`
    width: 100%;
    overflow: hidden;
    background: ${Colors.white};
    box-shadow: ${Shadows.navigationShadow};
    border-radius: ${Radius.full};

    ${({ height }) =>
        height &&
        height > 0 &&
        `
            max-height: ${height}px !important;
            height: ${height}px;
    `}
`;

export const Header = styled.div<HeaderProps>`
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: ${Spacing.small3};
    padding: ${Spacing.small3} ${Spacing.small} ${Spacing.small5} ${Spacing.small};
    @media ${BreakpointsMinQuery.sm} {
        padding: ${Spacing.small5} ${Spacing.medium} ${Spacing.medium2} ${Spacing.medium};
    }
    @media ${BreakpointsMinQuery.md} {
        padding: ${Spacing.medium3} 0 ${Spacing.small5} 0;
        margin: 0 ${Spacing.medium3};
    }
    @media ${BreakpointsMinQuery.lg} {
        border-bottom: 1px solid ${Colors.gray15};
    }
`;

export const Inner = styled.div<ModalInnerType>`
    padding: ${({ noPadding }) => (noPadding ? 0 : '0 16px 20px 20px')};
    @media ${BreakpointsMinQuery.sm} {
        padding: ${({ noPadding }) => (noPadding ? 0 : '0 10px 40px 40px')};
    }
    @media ${BreakpointsMinQuery.md} {
        padding: ${({ footer }) => (footer ? '0 40px 0 40px' : '0 40px 40px 40px')};
        position: relative;
    }
    @media ${BreakpointsMinQuery.lg} {
        padding: ${({ footer }) => (footer ? '0 40px 0 40px' : '0 40px 40px 40px')};
    }
    scrollbar-color: ${Colors.primary};
    background: ${Colors.white};
    @media ${BreakpointsMinQuery.md} {
        max-height: 650px;
        @media screen and (max-height: 1080px) {
            max-height: 600px;
        }
        overflow-y: overlay;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            margin-top: ${Spacing.small5};
            margin-bottom: ${Spacing.small5};
            @media (min-width: 600px) {
                margin-top: ${Spacing.medium3};
                margin-bottom: ${Spacing.small5};
            }
        }

        &::-webkit-scrollbar-thumb {
            border-right: 2px solid ${Colors.black};
            border-radius: ${Radius.minimal};
        }
    }
    ${({ height }) =>
        height &&
        height > 0 &&
        `
         max-height: none !important; 
         overflow. initial;
    `}
`;

export const Close = styled(Icon)`
    cursor: pointer;
    padding: 20px;
    margin: -20px;
    box-sizing: content-box;
    margin-left: auto;
`;

export const Footer = styled.div`
    padding: ${Spacing.small} 0;
    @media ${BreakpointsMinQuery.sm} {
        padding: ${Spacing.small3} 0 ${Spacing.medium} 0;
    }
    @media ${BreakpointsMinQuery.md} {
        padding: ${Spacing.medium3} 0 ${Spacing.medium3} 0;
        position: sticky;
        bottom: 0;
        background: ${Colors.white};
    }
`;
