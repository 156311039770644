import { gql } from '@apollo/client';

export type CreateShoppingListResponse = {
    createShoppingList: {
        name: string;
        id: string;
    };
};

const CREATE_SHOPPING_LIST = gql`
    mutation CreateShoppingListMutation($shoppingListInput: ShoppingListInput!) {
        createShoppingList(shoppingListInput: $shoppingListInput) {
            id
            name
        }
    }
`;

export default CREATE_SHOPPING_LIST;
