import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { Container } from '@ab-core/grid';
import { Radius } from '@ab-core/radius';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';
import CollectionItems from './collectionItems';

const FIRST_MENU = 0;
const SECOND_MENU = 1;
const LAST_MENU = 2;

export const MenuWrapper = styled.div`
    position: relative;
    width: 100%;
    max-height: 100%;
    color: ${Colors.white};
    height: inherit;
`;

export const StyledCollectionItems = styled(CollectionItems)`
    &.ab-collectionItems-1 {
        display: ${({ lastMenu }) => (lastMenu === FIRST_MENU ? 'block' : 'none')};
        @media ${BreakpointsMinQuery.md} {
            width: 50%;
            display: ${({ lastMenu }) => (lastMenu === LAST_MENU ? 'none' : 'block')};
        }
        @media ${BreakpointsMinQuery.lg} {
            width: 17.5%;
            display: block;
        }
        @media ${BreakpointsMinQuery.xl} {
            min-width: 15%;
            width: 15%;
            display: block;
        }
    }
    &.ab-collectionItems-2 {
        display: ${({ lastMenu }) => (lastMenu === SECOND_MENU ? 'block' : 'none')};
        @media ${BreakpointsMinQuery.md} {
            ${({ lastMenu }) => (lastMenu === LAST_MENU ? `width: 25%;` : `width: 50%;`)};
            display: block;
        }
        @media ${BreakpointsMinQuery.lg} {
            width: 17.5%;
            display: block;
            margin-left: 17.5%;
        }
        @media ${BreakpointsMinQuery.xl} {
            min-width: 15%;
            width: 15%;
            margin-left: 15%;
            display: block;
        }
    }
    &.ab-collectionItems-3 {
        display: ${({ lastMenu }) => (lastMenu === LAST_MENU ? 'block' : 'none')};
        @media ${BreakpointsMinQuery.md} {
            width: 75%;
        }
        @media ${BreakpointsMinQuery.lg} {
            width: 65%;
            margin-left: 35%;
            display: block;
        }
        @media ${BreakpointsMinQuery.xl} {
            width: 70%;
            min-width: 70%;
            margin-left: 30%;
            display: block;
        }
    }
`;

export const StyledContainer = styled(Container)`
    padding: 0;
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    display: flex;
    z-index: ${Z_INDEX.MODAL};
    flex-direction: column;

    @media ${BreakpointsMinQuery.lg} {
        top: 100px;
        border-radius: ${Radius.minimal};
        box-shadow: ${Shadows.navigationShadow};
    }

    .content {
        width: 100%;
        @media ${BreakpointsMinQuery.lg} {
            top: -100px;
        }
    }
    left: 0;
`;

type BackdropType = { left: string };

export const BackdropWrapper = styled.div<BackdropType>`
    background-color: ${Colors.black};
    width: 100vw;
    position: absolute;
    height: 100vh;
    opacity: 0.5;
    pointer-events: none;
`;
