import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { DefaultMutationReturnType } from '@ab-core/types/src';
import { useState, useEffect } from 'react';
import { AccountInput } from '@ab-core/graphql/dist';
import USER_SELF_ONBOARDING, { CreateUserSelfOnboardingResponse } from './createUserSelfOnboardingMutation';

type InviteUserMutationResponseType<T> = DefaultMutationReturnType<T> & {
    selfOnboarding: (options?: MutationFunctionOptions) => void;
};

type InviteUserReturnType = InviteUserMutationResponseType<CreateUserSelfOnboardingResponse>;

const SelfOnboarding = (accountInput?: AccountInput): InviteUserReturnType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<CreateUserSelfOnboardingResponse | undefined>(undefined);
    const [pending, setPending] = useState(false);
    const [selfOnboarding, { loading }] = useMutation<CreateUserSelfOnboardingResponse>(USER_SELF_ONBOARDING, {
        variables: { accountInput, createUser: true },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        selfOnboarding,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
export default SelfOnboarding;
