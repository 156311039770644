import { MutationFunctionOptions, useMutation } from '@apollo/client';

import { Cart } from '@ab-core/graphql/dist';
import { toast } from '@ab-core/toast';
import { useEffect, useState } from 'react';
import SHOPPING_LIST_QUERY from './getShoppingListQuery';
import SHOPPING_LISTS_QUERY from './getShoppingListsQuery';
import { UPDATE_SHOPPING_LIST_LINE_ITEM } from './updateShoppingListLineItem';

type UpdateShoppingListLineItemType = {
    success: boolean;
    error: boolean;
    errorMessage?: string;
    pending: boolean;
    response?: Cart;
    updateShoppingListLineItem: (options?: MutationFunctionOptions) => void;
};

export const UseUpdateShoppingListLineItem = (shoppingListId?: string): UpdateShoppingListLineItemType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState(undefined);
    const [pending, setPending] = useState(false);
    const [updateShoppingListLineItem, { loading }] = useMutation(UPDATE_SHOPPING_LIST_LINE_ITEM, {
        variables: {
            shoppingListId
        },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
            if (res) {
                toast.success(`Menge geändert`, `Die neue Menge wurde gespeichert`);
            }
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        },
        refetchQueries: [
            {
                query: SHOPPING_LISTS_QUERY
            },
            { query: SHOPPING_LIST_QUERY, variables: { shoppingListId } }
        ]
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);
    return {
        updateShoppingListLineItem,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
