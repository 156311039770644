import { Cart, QueryGetMyCartsArgs } from '@ab-core/graphql/dist/index';
import { useSessionCart } from '@ab-core/hooks/user/useSessionCart';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import cartMapper, { MappedCart } from '../mappers/cartMapper';
import { CARTS } from './getMyCartsQuery';

type Data = {
    getMyCarts?: Cart[];
};

type UseMyCartsType = {
    myCarts?: MappedCart[];
    myCartsError?: ApolloError;
    myCartsLoading: boolean;
    myCartsRefetch: (_?: QueryGetMyCartsArgs) => Promise<ApolloQueryResult<Data>>;
};
const useMyCarts = (withActiveCart = false, skip = false): UseMyCartsType => {
    const { checkForSessionCart } = useSessionCart();
    const { ociSessionId } = checkForSessionCart();
    const { error, data, loading, refetch } = useQuery<Data, QueryGetMyCartsArgs>(CARTS, {
        variables: { sessionId: ociSessionId },
        fetchPolicy: 'network-only',
        skip
    });

    const [myCarts, setMyCarts] = useState<Array<MappedCart>>([]);

    useEffect(() => {
        const mappedCarts = loading || error ? [] : data?.getMyCarts?.map((cart) => cartMapper(cart));
        const myCartsTemp = mappedCarts
            ?.filter((mappedCart) => (withActiveCart ? true : !mappedCart?.isActiveCart))
            ?.filter((mappedCart) => !mappedCart?.isIdsCart)
            ?.sort((a, b) => (b?.lastModifiedAt || new Date()).getTime() - (a?.lastModifiedAt || new Date()).getTime());
        setMyCarts(myCartsTemp || []);
    }, [data]);

    return {
        myCarts,
        myCartsError: error,
        myCartsLoading: loading,
        myCartsRefetch: refetch
    };
};

export default useMyCarts;
