import { Colors } from '@ab-core/colors';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import styled, { keyframes } from 'styled-components';
import type { SkeletonCardProps } from '.';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

enum HEIGHTS {
    SMALL = '16px',
    MEDIUM_SMALL = '20px',
    MEDIUM = '32px',
    LARGE = '75px',
    EXTRA_LARGE = '128px',
    PRODUCT_CARD = '600px',
    LIST_CARD = '200px',
    CARD = '400px'
}
const getHeight = (
    small = false,
    medium = false,
    mediumSmall = false,
    large = false,
    extraLarge = false,
    height?: number,
    productCard = false,
    productListCard = false,
    card = false
): string => {
    if (card) {
        return HEIGHTS.CARD;
    }

    if (height) {
        return `${height.toString()}px`;
    }

    if (small) {
        return HEIGHTS.SMALL;
    }

    if (medium) {
        return HEIGHTS.MEDIUM;
    }

    if (mediumSmall) {
        return HEIGHTS.MEDIUM_SMALL;
    }

    if (large) {
        return HEIGHTS.LARGE;
    }

    if (extraLarge) {
        return HEIGHTS.EXTRA_LARGE;
    }

    if (productCard) {
        return HEIGHTS.PRODUCT_CARD;
    }

    if (productListCard) {
        return HEIGHTS.LIST_CARD;
    }

    return HEIGHTS.MEDIUM;
};

const getWidth = (width?: number, short = false): string => {
    if (width) {
        return `${width.toString()}px`;
    }

    if (short) {
        return '60%';
    }

    return '100%';
};

export const SkeletonWrapper = styled.div<SkeletonCardProps>`
    height: ${({ small, medium, mediumSmall, large, extraLarge, height, productCard, productListCard, card }) =>
        getHeight(small, medium, mediumSmall, large, extraLarge, height, productCard, productListCard, card)};
    width: ${({ width, short }) => getWidth(width, short)};
    animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
    background-color: ${Colors.gray15};
    background-image: linear-gradient(90deg, ${Colors.gray15}, ${Colors.white}, ${Colors.gray15});
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: ${Radius.minimal};
    margin-top: 0;
    margin-bottom: ${({ noMa }) => (noMa ? '0px' : `${Spacing.small2}`)};
`;
