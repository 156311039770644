import type { AdditionalCheckFunctionType } from '../../hasPermission';

export type UserExceedValueCheckProps = {
    price: number;
};

export const userExceedValueCheck: AdditionalCheckFunctionType<UserExceedValueCheckProps> = (props) => {
    const num = props.featureAccess?.feature?.amountValue || 0;
    const price = props.payload?.price || 0;

    return num ? num < price : false;
};
