import type { PromotionalSheetInput } from '@ab-core/graphql/dist';

export const promotionalTemplateMockData: PromotionalSheetInput = {
    headline: 'Unser Angebot für Sie',
    priceColor: 'schwarz',
    legalNotice: 'Nur so lange der Vorrat reicht.',
    showPrice: true,
    showUvp: false,
    showVat: false,
    footerText: '',
    companyImage: '',
    lastName: '',
    firstName: '',
    email: '',
    phoneNumber: '',
    showEan: true,
    showProductDescription: true,
    showTechnicalData: false
};
