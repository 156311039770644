import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const Item = styled.div`
    padding: ${Spacing.small3};
    padding-left: calc(${Spacing.small3} - 4px);
    border-left: 4px solid transparent;
    background: ${Colors.white};
    outline: none;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: ${Colors.backgroundShade};
    }
    &:focus {
        border-color: ${Colors.primary};
    }

    &.ab-dropdown-item-disabled {
        color: ${Colors.gray50};
        background: ${Colors.white};
        border-color: transparent;
        cursor: default;
    }

    &.ab-dropdown-item-selected {
        border-color: ${Colors.primary};
        cursor: default;
    }
`;
