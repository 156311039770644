import { gql } from '@apollo/client';

const PROMOTIONAL_PRODUCT_QUERY = gql`
    query GetPromotionProductBySku($sku: String!) {
        getProductBySku(sku: $sku) {
            id
            ean
            sku
            image {
                url
                label
            }
            description
            productName
            supplierProductNumber
            customTariffNumber
            salesUnit
            packagingSize
            quantityUnit
            weight
        }
    }
`;

export default PROMOTIONAL_PRODUCT_QUERY;
