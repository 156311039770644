import { Colors } from '@ab-core/colors';
import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const Element = styled.div`
    padding: ${Spacing.small};
    margin: ${Spacing.small};
    &:hover {
        background-color: ${Colors.backgroundShade};
    }
`;
