export enum Colors {
    black = '#000000',
    white = '#ffffff',
    backgroundShade = '#F5F5F5',
    primary = '#EA002A',
    primaryDarkenOne = '#B30021',
    primaryDarkenTwo = '#660013',
    primaryLightenOne = '#F58095',
    primaryLightenTwo = '#F8A6B4',
    success = '#279989',
    successDarkenOne = '#1F7A6E',
    successDarkenTwo = '#155149',
    successLightenOne = '#93CCC4',
    successLightenTwo = '#B3DBD6',
    warning = '#FF8200',
    warningDarkenOne = '#E67700',
    warningDarkenTwo = '#B36D00',
    warningLightenOne = '#FFC180',
    warningLightenTwo = '#FFD3A6',
    info = '#236192',
    infoDarkenOne = '#194567',
    infoDarkenTwo = '#0F293E',
    infoLightenOne = '#91B0C9',
    infoLightenTwo = '#B2C8D9',
    error = '#9B2242',
    errorDarkenOne = '#7D1C36',
    errorDarkenTwo = '#6C183F',
    errorLightenOne = '#CD91A1',
    errorLightenTwo = '#DCB2BD',
    gray15 = '#E1E1E1',
    gray30 = '#C8C8C8',
    gray50 = '#9B9B9B',
    gray70 = '#6E6E6E'
}

export enum Shadows {
    componentShadow = '0px 2px 4px rgba(0, 0, 0, 0.16)',
    navigationShadow = '0px 8px 16px rgba(0, 0, 0, 0.16)'
}
