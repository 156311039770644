import type { Offer } from '@ab-core/graphql/dist';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { OFFERS_QUERY } from './getOffersQuery';

type Data = {
    offers?: Array<Offer>;
};

export type MappedOffer = {
    offerDate?: Date;
    bindingPeriod?: Date;
} & Omit<Offer, 'offerDate' | 'bindingPeriod'>;

export type UseOffersType = {
    offers?: Array<MappedOffer>;
    offersError?: ApolloError;
    offersLoading: boolean;
    lastPage?: number;
    refetch: () => Promise<ApolloQueryResult<Data>>;
};

const useOffers = (): UseOffersType => {
    const { error, data, loading, refetch } = useQuery<Data>(OFFERS_QUERY, {
        fetchPolicy: 'no-cache'
    });
    const offerList = data?.offers?.map((offer) => ({
        ...offer,
        ...{
            offerDate: offer.offerDate ? new Date(offer.offerDate) : undefined,
            bindingPeriod: offer.bindingPeriod ? new Date(offer.bindingPeriod) : undefined
        }
    }));

    return {
        refetch,
        offers: offerList,
        offersError: error,
        offersLoading: loading
    };
};

export default useOffers;
