import { gql } from '@apollo/client';

export type UpdateCostCenterResponse = {
    updateCostCenter: {
        name: string;
        description: string;
    };
};

export const UPDATE_COST_CENTER = gql`
    mutation UpdateCostCenter($data: CostCenterUpdateInput!) {
        updateCostCenter(data: $data) {
            name
        }
    }
`;
