import { Spacing } from '@ab-core/spacing';
import styled from 'styled-components';

export const PointsOverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${Spacing.small};
    padding: ${Spacing.small5};
`;
