import { gql } from '@apollo/client';
import SHOPPING_LIST_LINEITEM from '../fragments/shoppingListLineItemFragment';

const SHOPPING_LIST_QUERY = gql`
    query ShoppingList($shoppingListId: String!) {
        shoppingList(id: $shoppingListId) {
            id
            name
            slug
            lastModifiedAt
            shareState
            createdBy {
                contactId
                name
            }
            lineItems {
                ...shoppingListLineitem
            }
        }
    }
    ${SHOPPING_LIST_LINEITEM}
`;

export default SHOPPING_LIST_QUERY;
