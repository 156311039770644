import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export const SHARED_CARTS = gql`
    query getSharedCarts {
        getSharedCarts {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
