import { gql } from '@apollo/client';

const PRODUCT_PAGE_DATA = gql`
    fragment productPageFields on Product {
        id
        slug
        description
        categories
        categoryKey
        version
        relationType
        sku
        productName
        ean
        supplierId
        supplierProductType
        supplierProductLink
        supplierProductNumber
        supplierUnit
        oxomiProductNumber
        image
        images {
            url
            label
        }
        procuredProduct
        productCocontractor
        salesUnit
        weight
        packagingSize
        customTariffNumber
        salesNumber
        maxAvailableQuantity
        quantityUnit
        topProduct
        reachInfo
        isTecselect
        isAbakus
        isAbakusPlus
        isPromotion
        scoreCardIndex
        isUnqualifiedContractProduct
        onlineAvailable
        customerArticleNumber
        pickupStoreFreiburg {
            channel {
                name
                primaryChannel
                id
                key
                address {
                    id
                    externalId
                    primaryAddress
                    name
                    streetName
                    city
                    postalCode
                    country
                    addressExtraLineOne
                    addressExtraLineTwo
                    addressExtraLineThree
                }
            }
            availability {
                availableQuantity
            }
        }
        productDocumentsDeha {
            name
            link
        }
        productFeatures {
            unit
            featureValueScoped {
                minValue
                maxValue
            }
            featureValues
            featureName
        }
    }
`;

export default PRODUCT_PAGE_DATA;
