import { gql } from '@apollo/client';

export type SetActiveCartResponseType = {
    setCartInformation: {
        isActiveCart: boolean;
        id: string;
        name: string;
        customLineItems: Array<{
            id: string;
        }>;
        lineItems: Array<{ id: string }>;
    };
};

const SET_ACTIVE_CART = gql`
    mutation setActiveCart($cartId: String!, $cartInformationInput: CartInformationInput) {
        setCartInformation(cartId: $cartId, cartInformationInput: $cartInformationInput) {
            isActiveCart
            id
            name
            customLineItems {
                id
            }
            lineItems {
                id
            }
        }
    }
`;
export default SET_ACTIVE_CART;
