import { Shadows } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { FormElementHeight } from '@ab-core/forms';
import { Radius } from '@ab-core/radius';
import styled, { css } from 'styled-components';
import { buttonVariantGray, buttonVariantGreen, buttonVariantRed, buttonVariantWhite } from './styledVariants';

const font = css`
    font-weight: ${FontTypes.button.fontWeight};
    font-size: ${FontTypes.button.fontSize};
    line-height: ${FontTypes.button.lineHeight};
    letter-spacing: ${FontTypes.button.letterSpacing};
    text-transform: ${FontTypes.button.textTransform};
`;

export const Button = styled.button`
    ${font}
    display: flex;
    flex-direction: row;
    word-break: normal;
    &.ab-button-iconPosition-left {
        flex-direction: row-reverse;
    }
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 24px;
    border-style: none;

    height: ${FormElementHeight.Normal};

    &.ab-button-fluid {
        width: 100%;
    }

    border-radius: ${Radius.minimal};
    box-shadow: ${Shadows.componentShadow};

    cursor: pointer;
    &:focus {
        outline: none;
    }

    &.ab-button-disabled {
        cursor: auto;
    }
    ${buttonVariantRed}
    ${buttonVariantWhite}
    ${buttonVariantGray}
    ${buttonVariantGreen}
`;

export const Group = styled.div`
    display: inline-flex;
    max-width: 100%;
    & > .ab-button {
        border: none !important;
        box-shadow: none !important;
    }
    & > div > .ab-button {
        border: none !important;
        box-shadow: none !important;
    }
`;
