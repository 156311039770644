import { useQuery } from '@apollo/client';
import { useState } from 'react';
import type CustomContact from './contact';
import { GET_CONTACTS_WITHOUT_RIGHTS } from './contact/getContactsWithoutRight';

export type UseCustomerDataReturnType = {
    contacts?: Array<CustomContact>;
    loading: boolean;
    getContactsWithoutRights?: Array<CustomContact>;
    refetch: () => void;
    success?: boolean;
};

const useCustomerData = (): UseCustomerDataReturnType => {
    const [success, setSuccess] = useState(false);

    const { data, loading, refetch } = useQuery<UseCustomerDataReturnType>(GET_CONTACTS_WITHOUT_RIGHTS, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setSuccess(true);
        }
    });
    const contactList: CustomContact[] = data?.getContactsWithoutRights || [];

    const customerData = contactList.map((item: CustomContact) => ({
        ...item,
        ...{ show: true }
    }));

    return {
        contacts: customerData,
        loading,
        refetch,
        success
    };
};

export default useCustomerData;
