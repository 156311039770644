import { Colors } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { FormElementHeight } from '@ab-core/forms';
import { Radius } from '@ab-core/radius';
import { Spacing } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled, { css } from 'styled-components';

const formLabelAnimation = css`
    font-size: 0.85em;
    transform: translateY(-16px);
    & > .ab-label-span {
        background-color: ${Colors.white};
    }
`;
const font = css`
    font-weight: ${FontTypes.body.fontWeight};
    font-size: ${FontTypes.body.fontSize};
    letter-spacing: ${FontTypes.body.letterSpacing};
    text-transform: ${FontTypes.body.textTransform};
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;

export const InputContainer = styled.div`
    position: relative;
    .ab-input {
        ${font}
        width: 100%;
        padding: 12px;
        height: ${FormElementHeight.Normal};
        border: 1px solid ${Colors.gray70};
        border-radius: ${Radius.minimal};
        resize: vertical;
        color: ${Colors.black};
        &:valid {
            color: ${Colors.black};
        }
        &::placeholder {
            opacity: 0;
            user-select: none;
        }

        &.ab-input-error {
            border: 1px solid ${Colors.error};
            color: ${Colors.error};
        }
        &:focus {
            outline: none;
            color: ${Colors.black};
            border: 1.25px solid ${Colors.black};
        }
        &:disabled {
            border: 1px solid ${Colors.gray70};
            background: ${Colors.gray15};
        }
    }
    .ab-input-hasIcon {
        padding-right: 50px;
    }
    .ab-label {
        position: absolute;
        color: ${Colors.gray70};
        top: 10px;
        left: 12px;
        pointer-events: none;
        transition: 0.5s;
        ${font}
        & > .ab-label-span {
            background-color: transparent;
        }
        &.ab-label-error {
            color: ${Colors.error};
        }
        ${`width: calc(100% - ${Spacing.medium});`}
    }
    .ab-input ~ .ab-label {
        //  top: 10px;
    }
    .ab-input:disabled ~ .ab-label {
        color: ${Colors.gray70};
    }

    .ab-input:not(:placeholder-shown) ~ .ab-label {
        color: ${Colors.gray70};
        ${formLabelAnimation};
        &:disabled {
            color: ${Colors.gray70};
        }
    }
    .ab-input:focus ~ .ab-label {
        color: ${Colors.gray70};

        ${formLabelAnimation};
    }

    .ab-input:not(:placeholder-shown):disabled ~ .ab-label {
        ${formLabelAnimation};
        color: ${Colors.gray70};
    }
    .ab-input-error:not(:placeholder-shown) ~ .ab-label-error {
        ${formLabelAnimation};
        color: ${Colors.error};
    }
    .pseudoElementLine {
        margin-left: -2px;
        margin-right: -2px;
        z-index: ${Z_INDEX.BELOW};
        position: absolute;
        height: 2px;
        top: 6px;
        right: 0;
        left: 0;
    }
`;
