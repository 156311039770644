import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export const RECEIVED_CARTS = gql`
    query getCarts {
        getCarts {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
