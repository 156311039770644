import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { Icon } from '@ab-core/icon';
import Spacer from '@ab-core/spacer';
import Text, { FontTypes } from '@ab-core/text';
import { Z_INDEX } from '@ab-core/z-index';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { FavoriteEditButton } from '../';
import type { EditModalProps } from '../editModal';
import { CHANGE_TYPE, EditModal } from '../editModal';
import { Headline } from '../headline';
import { FavoritItemContainer, FavoritItemInner, FavoriteListWrapper, InfoContainer } from './styled';
import type { FavoriteItemType } from './types';
import { removeFavoriteFromListByUrl, useGetFavoriteList, useSaveFavoriteList } from './utils';

export const FavoriteList: FC = () => {
    const favoriteList = useGetFavoriteList();
    const [items, setItems] = useState(favoriteList);
    const [savedItems, setSavedItems] = useState(favoriteList);
    const [editable, setEditable] = useState(false);
    const [draggingIndex, setDraggingIndex] = useState<number | undefined>(undefined);
    const [modalItem, setModalItem] = useState<FavoriteItemType | undefined>(undefined);
    const { saveFavoriteList } = useSaveFavoriteList(items);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setItems(favoriteList);
        setSavedItems(favoriteList);
    }, [favoriteList]);

    const hasChanges = JSON.stringify(items) === JSON.stringify(savedItems);

    const favoriteListWrapperClass = getClassNames({
        prefix: 'favorite-list-wrapper',
        modifier: { editable }
    });

    const favoriteItemClass = getClassNames({
        prefix: 'favorite-item',
        modifier: { editable }
    });

    const favoriteDummyItemClass = getClassNames({
        prefix: 'favorite-dummy-item',
        modifier: { 'is-dragging': Boolean(draggingIndex || draggingIndex === 0) }
    });

    const favoriteTextClass = getClassNames({
        prefix: 'favorite-item-name'
    });

    const handleItemClick = (url: FavoriteItemType['url']) => {
        if (editable) {
            return;
        }

        navigate(url);
    };

    const toggleEdit = () => {
        if (editable) {
            setItems(savedItems);
        }

        setEditable(!editable);
    };

    const savingItems = () => {
        setEditable(false);
        saveFavoriteList();
        setSavedItems(items);
    };

    const editItem = (item: FavoriteItemType) => setModalItem(item);

    const onModalClose = () => setModalItem(undefined);

    const handleItemChange: EditModalProps['handleItemChange'] = (item, type) => {
        if (type === CHANGE_TYPE.DELETE) {
            const oldItems = [...items];

            setItems(removeFavoriteFromListByUrl(oldItems, item.url));
        }

        if (type === CHANGE_TYPE.UPDATE) {
            const newItems = [...items];
            const index = newItems.findIndex((listItem) => listItem.url === item.url);

            newItems[index] = item;
            setItems(newItems);
        }
    };

    const handlePointerDown = (pointerDownEvent: React.PointerEvent<HTMLDivElement>, index: number) => {
        if (!editable) {
            return;
        }

        const container = containerRef.current;

        if (!container?.hasChildNodes()) {
            return;
        }

        setDraggingIndex(index);

        const children = Array.from(container.childNodes) as Array<HTMLDivElement>;
        const dragItem = children[index];
        const notDragItems = children.filter((_, i) => i !== index);
        const dragData = items[index];
        let newData = [...items];

        const dragBoundingRect = dragItem.getBoundingClientRect();

        dragItem.setAttribute(
            'style',
            `
            position: fixed; 
            z-index: ${Z_INDEX.TOOLTIP}; 
            top: ${dragBoundingRect.top}px; 
            left: ${dragBoundingRect.left}px;
            opacity: 90%;
            `
        );

        const x = pointerDownEvent.clientX;
        const y = pointerDownEvent.clientY;

        document.onpointermove = dragMove;
        function dragMove(moveEvent: PointerEvent) {
            const posX = moveEvent.clientX - x;
            const posY = moveEvent.clientY - y;

            dragItem.style.transform = `translate(${posX}px, ${posY}px)`;

            let newIndex = index;

            notDragItems.forEach((notDragItem, notDragItemIndex) => {
                const rect1 = dragItem.getBoundingClientRect();
                const rect2 = notDragItem.getBoundingClientRect();

                const isOverlappingY =
                    rect1.y + rect1.height / 2 > rect2.y && rect1.y + rect1.height / 2 < rect2.y + rect2.height;
                const isOverlappingX =
                    rect1.x + rect1.width / 2 > rect2.x && rect1.x + rect1.width / 2 < rect2.x + rect2.width;
                const isDummyItem = notDragItem.classList.contains('ab-favorite-dummy-item');

                if (isOverlappingY && isOverlappingX && !isDummyItem) {
                    if (notDragItemIndex < index) {
                        newIndex = notDragItemIndex;
                    } else {
                        newIndex = notDragItemIndex + 1;
                    }
                }

                newData = items.filter((i) => i.url !== dragData.url);
                newData.splice(newIndex, 0, dragData);
            });
        }

        document.onpointerup = dragEnd;
        function dragEnd() {
            document.onpointerup = null;
            document.onpointermove = null;

            children.forEach((child) => {
                child.setAttribute('style', '');
            });

            setItems(newData);
            setDraggingIndex(undefined);
        }
    };

    if (savedItems.length <= 0) {
        return <></>;
    }

    return (
        <>
            <Headline
                isEditMode={editable}
                hasChanges={hasChanges}
                onSave={savingItems}
                onCancel={toggleEdit}
                onEdit={toggleEdit}
            />
            {editable && (
                <InfoContainer>
                    <Text type={FontTypes.Caption} color="info">
                        Um die Reihenfolge zu verändern, ziehen Sie das Favoriten-Element an die gewünschte Position
                    </Text>
                    <Text type={FontTypes.Caption} color="primary">
                        {!hasChanges && 'Es gibt ungespeicherte Änderungen'}
                        {hasChanges && <>&nbsp;</>}
                    </Text>
                </InfoContainer>
            )}
            <Spacer />
            <FavoriteListWrapper ref={containerRef} className={favoriteListWrapperClass}>
                {items?.map((item, index) => (
                    <FavoritItemContainer
                        key={item.url}
                        onClick={() => handleItemClick(item.url)}
                        onPointerDown={(e) => handlePointerDown(e, index)}
                        className={favoriteItemClass}
                        order={index}
                    >
                        <FavoritItemInner>
                            {item.icon && <Icon name={item.icon} size="medium3" />}
                            <Text color="inherit" className={favoriteTextClass} type={FontTypes.Caption}>
                                {item.name}
                            </Text>
                        </FavoritItemInner>
                        {editable && <FavoriteEditButton onClick={() => editItem(item)} />}
                    </FavoritItemContainer>
                ))}
                <FavoritItemContainer className={favoriteDummyItemClass} order={draggingIndex} />
            </FavoriteListWrapper>
            <EditModal item={modalItem} handleItemChange={handleItemChange} onClose={onModalClose} />
        </>
    );
};
