import useText from '@ab-core/localization/src/useText';
import { toast } from '@ab-core/toast';
import type { DefaultMutationReturnType } from '@ab-core/types/src';
import type { MutationFunctionOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_COST_CENTERS } from './getCostCenters';
import type { CreateCostCenterResponse } from './postCreateCostCenter';
import { CREATE_COST_CENTER } from './postCreateCostCenter';

type CreateCostCenterMutationResponseType<T> = DefaultMutationReturnType<T> & {
    createCostCenter: (options?: MutationFunctionOptions) => void;
};

type CreateCostCenterReturnType = CreateCostCenterMutationResponseType<CreateCostCenterResponse>;

export const useCreateCostCenter = (): CreateCostCenterReturnType => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<CreateCostCenterResponse | undefined>(undefined);
    const [pending, setPending] = useState(false);

    const errorNotificationText = useText('costCenters.errorNotification');

    const [createCostCenter, { loading }] = useMutation<CreateCostCenterResponse>(CREATE_COST_CENTER, {
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
            toast.success(`Kostenstelle ${res.createCostCenter.name} wurde angelegt`);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
            toast.error(errorNotificationText);
        },
        refetchQueries: [
            {
                query: GET_COST_CENTERS
            }
        ]
    });
    useEffect(() => {
        if (loading) {
            setPending(true);
        }
    }, [loading]);

    return {
        createCostCenter,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
