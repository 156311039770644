import { ApolloQueryResult, useQuery } from '@apollo/client';
import { QueryCustomerDocumentArgs, CustomerDocument } from '@ab-core/graphql/dist/index';

import DOCUMENT_QUERY, { CustomerDocumentType } from './getDocumentQuery';

enum CustomerDocumentEnum {
    DeliveryNote = 'DeliveryNote',
    Invoice = 'Invoice',
    Refund = 'Refund'
}
type ReturnType = {
    results?: CustomerDocument;
    pending: boolean;
    refetch: (_: QueryCustomerDocumentArgs) => Promise<ApolloQueryResult<CustomerDocumentType>>;
};

const generatePdf = (documentNumber: string, documentType: CustomerDocumentEnum | string, skip = false): ReturnType => {
    const { loading, data, refetch } = useQuery(DOCUMENT_QUERY, {
        fetchPolicy: 'network-only',

        variables: {
            documentNumber,
            documentType
        },
        skip: !documentType || documentType === 'null' || skip
    });

    return {
        refetch,
        pending: loading,
        results: data?.customerDocument
    };
};
export default generatePdf;
